
import './add-new-component.scss'
import { v4 as uuid } from 'uuid';
import React, { useState } from "react";

import arrow from '../../../assets/images/curve-arrow.svg';
import statement from '../../../assets/images-v2/statement.png';
import name from '../../../assets/images-v2/name.png';
import phone from '../../../assets/images-v2/phone.png';
import email from '../../../assets/images-v2/email.png';
import button from '../../../assets/images-v2/button.png';
import multi_select from '../../../assets/images-v2/multi_select.png';
import question from '../../../assets/images-v2/question.png';
import file from '../../../assets/images-v2/file.png';
import appointment from '../../../assets/images-v2/appointment.png';
import location from '../../../assets/images-v2/location.png';
import date from '../../../assets/images-v2/date.png';
import image from '../../../assets/images-v2/image.png';
import contact from '../../../assets/images-v2/contact.png';
import rating from '../../../assets/images-v2/rating.png';
import range from '../../../assets/images-v2/range.png';
import number from '../../../assets/images-v2/number.png';
import video from '../../../assets/images-v2/video.png';
import smart_question from '../../../assets/images-v2/smart_question.png';
import live_chat from '../../../assets/images-v2/live_chat.png';
import chatGPT from '../../../assets/images-v2/chatGPT.png';
import redirect from '../../../assets/images/chat-components/redirect.svg';
import catalogue from '../../../assets/images-v2/catalogue.png';
import address from '../../../assets/images/chat-components/address.svg';
import single_product from '../../../assets/images/chat-components/single_product.svg';
import multi_product from '../../../assets/images/chat-components/multi_product.svg';

import { createQuestion } from "../../../utilities/create-question";
import { addQuestionChecks } from '../../../utilities/add-question-checks';
import { BotMethods } from '../../../services/bot';
import { useDispatch, useSelector } from 'react-redux';
import { LOAD_FLOW } from '../../../redux/actions/actions';
import { UPDATE_FLOW, UPDATE_LOADER } from '../../../redux/actions/actions';
import { useReactFlow } from 'react-flow-renderer';
import ComponentList from '../ComponentList';
import EventCanvas from '../EventListener';
import { getTooltipText } from "../../../assets/json/component-tooltip/index";
import { Tooltip } from '@mui/material';
import { getStaticText } from '../../../assets/json/component-static-text/index';


export default function AddNewComponent(props) {
  const dispatch = useDispatch();
  const reactFlowInstance = useReactFlow();
  let [filterby] = useState('');
  const actualflow = useSelector(state => state.flow);
  let flowQuestions = useSelector(state => state.flow.questions);
  let [showFlowComponents, setshowFlowComponents] = useState(false);
  const isChatGPTEnabled = useSelector(state => state.isChatGPTEnabled);
  const agency = useSelector(state => state.agency);
  const staticTexts = getStaticText();
  const tooltipTexts = getTooltipText();
  const botType = useSelector(state => state.currentBotDetails.botType);
  const { updateBot }  = BotMethods();


  const toggleFlowComponents = () => {
    setshowFlowComponents(!showFlowComponents)
  }

  const addComponent = (type) => {
    let text = staticTexts[type];
    if (text.toLowerCase().trim().includes('botpenguin') && agency.meta.brand) {
      text = text.replace(/botpenguin/gi, agency.meta.brand)
    }

    let que = createQuestion(type, text);
    que = addQuestionChecks(botType, type, que);

    const finalPayload = {
      ...actualflow,
      questions: [...flowQuestions, que]
    }
    dispatch({ type: LOAD_FLOW, payload: finalPayload });

    updateBot(finalPayload)
      .then((response) => {
        dispatch({ type: UPDATE_LOADER, payload: false });
        dispatch({ type: UPDATE_FLOW, payload: response.questions ? response.questions : response.flows[0].questions });
      })
      .catch(error => {
        dispatch({ type: UPDATE_LOADER, payload: false });
        console.log(error);
      });

    //Setting viewport again
    setTimeout(() => {
      try {
        const position = JSON.parse(sessionStorage.getItem('lastNodePosition'));

        reactFlowInstance.setViewport({ x: position.x + 400, y: 0 - position.y + 150, zoom: 1 }, { duration: 800 });

      } catch (error) {
        console.log(error);
      }

    }, 800);
  }

  const filter = (componentName, type) => {
    return componentName.match(new RegExp(filterby, 'gi')) &&
      (
        (botType?.toLowerCase() === 'telegram' && ['statement', 'question', 'email', 'phone', 'button', 'image', 'contact', 'number', 'location', 'video'].includes(type)) ||
        (botType?.toLowerCase() === 'facebook' && ['statement', 'question', 'email', 'phone', 'button', 'image', 'contact', 'number', 'location', 'video'].includes(type)) ||
        (botType?.toLowerCase() === 'instagram' && ['statement', 'question', 'email', 'phone', 'button', 'image', 'contact', 'number', 'video'].includes(type)) ||
        (botType?.toLowerCase() === 'whatsapp' && ['statement', 'catalogue', 'question', 'name', 'email', 'phone', 'button', 'image', 'contact', 'number', 'location', 'video', 'document', 'address', 'single_product', 'multi_product'].includes(type)) ||
        (botType?.toLowerCase() === 'website' && ['statement', 'question', 'name', 'email', 'phone', 'button', 'multi_select', 'appointment', 'image', 'rating', 'contact', 'date', 'range', 'number', 'location', 'video', 'file', 'live_chat', 'smart_question', 'chatGPT'].includes(type))
      );
  }

  const list = () => {
    return (<ul className="list-group b-none" style={{
      borderRadius: 'none',
      'maxHeight': '80vh',
      'overflowY': 'auto',
      display: showFlowComponents ? 'block' : 'none',
      backgroundColor: '#fff',
      padding: !props.hasFlow ? '18px 18px 0' : '0'
    }}>
      {
        filter('Message', 'statement') &&
        <Tooltip title={tooltipTexts.statement} placement="right">
          <li className={`${!props.hasFlow ? 'list-group-item-all-center' : 'list-group-item-all'} d-flex align-items-center`} onClick={() => { addComponent('statement'); toggleFlowComponents() }}>
            < img className="flow-img-all h-100" src={statement} alt="images"></img >
            <span className="list-item-title-all ">{staticTexts['messageText']} </span>
          </li >
        </Tooltip>
      }
      {
        filter('Name', 'name') &&
        <Tooltip title={tooltipTexts.name} placement="right">
          <li className={`${!props.hasFlow ? 'list-group-item-all-center' : 'list-group-item-all'} d-flex align-items-center`} onClick={() => { addComponent('name'); toggleFlowComponents() }}>
            <img className="flow-img-all h-100" src={name} alt="images"></img>
            <span className="list-item-title-all ">{staticTexts['nameText']} </span>
          </li>
        </Tooltip>
      }
      {
        filter('Phone Number', 'phone') &&
        <Tooltip title={tooltipTexts.phone} placement="right">
          <li className={`${!props.hasFlow ? 'list-group-item-all-center' : 'list-group-item-all'} d-flex align-items-center`} onClick={() => { addComponent('phone'); toggleFlowComponents() }}>
            <img className="flow-img-all h-100" src={phone} alt="images"></img>
            <span className="list-item-title-all ">{staticTexts['phoneNumberText']} </span>
          </li>
        </Tooltip>
      }
      {
        filter('Email', 'email') &&
        <Tooltip title={tooltipTexts.email} placement="right">
          <li className={`${!props.hasFlow ? 'list-group-item-all-center' : 'list-group-item-all'} d-flex align-items-center`} onClick={() => { addComponent('email'); toggleFlowComponents() }}>
            <img className="flow-img-all h-100" src={email} alt="images"></img>
            <span className="list-item-title-all ">{staticTexts['emailText']} </span>
          </li>
        </Tooltip>
      }
      {
        filter('Single Choice', 'button') &&
        <Tooltip title={tooltipTexts.singleChoice} placement="right">
          <li className={`${!props.hasFlow ? 'list-group-item-all-center' : 'list-group-item-all'} d-flex align-items-center`} onClick={() => { addComponent('button'); toggleFlowComponents() }}>
            < img className="flow-img-all h-100" src={button} alt="images" ></img >
            <span className="list-item-title-all ">{staticTexts['singleChoiceText']} </span>
          </li >
        </Tooltip>
      }
      {
        filter('Multiple Choice', 'multi_select') &&
        <Tooltip title={tooltipTexts.multipleChoice} placement="right">
          <li className={`${!props.hasFlow ? 'list-group-item-all-center' : 'list-group-item-all'} d-flex align-items-center`} onClick={() => { addComponent('multi_select'); toggleFlowComponents() }}>
            < img className="flow-img-all h-100" src={multi_select} alt="images" ></img >
            <span className="list-item-title-all ">{staticTexts['multipleChoiceText']} </span>
          </li >
        </Tooltip>
      }
      {
        filter('Text Question', 'question') &&
        <Tooltip title={tooltipTexts.question} placement="right">
          < li className={`${!props.hasFlow ? 'list-group-item-all-center' : 'list-group-item-all'} d-flex align-items-center`} onClick={() => { addComponent('question'); toggleFlowComponents() }}>
            < img className="flow-img-all h-100" src={question} alt="images" ></img >
            <span className="list-item-title-all ">{staticTexts['textQuestionsText']} </span>
          </li >
        </Tooltip>
      }
      {
        filter('File', 'file') &&
        <Tooltip title={tooltipTexts.file} placement="right">
          <li className={`${!props.hasFlow ? 'list-group-item-all-center' : 'list-group-item-all'} d-flex align-items-center`} onClick={() => { addComponent('file'); toggleFlowComponents() }}>
            < img className="flow-img-all h-100" src={file} alt="images" ></img >
            <span className="list-item-title-all ">{staticTexts['fileText']} </span>
          </li >
        </Tooltip>
      }
      {
        filter('Appointment', 'appointment') &&
        <Tooltip title={tooltipTexts.appointment} placement="right">
          <li className={`${!props.hasFlow ? 'list-group-item-all-center' : 'list-group-item-all'} d-flex align-items-center`} onClick={() => { addComponent('appointment'); toggleFlowComponents() }}>
            < img className="flow-img-all h-100" src={appointment} alt="images" ></img >
            <span className="list-item-title-all ">{staticTexts['appointmentText']} </span>
          </li >
        </Tooltip>
      }
      {
        filter('Location', 'location') &&
        <Tooltip title={tooltipTexts.location} placement="right">
          <li className={`${!props.hasFlow ? 'list-group-item-all-center' : 'list-group-item-all'} d-flex align-items-center`} onClick={() => { addComponent('location'); toggleFlowComponents() }}>
            < img className="flow-img-all h-100" src={location} alt="images" ></img >
            <span className="list-item-title-all ">{staticTexts['locationText']} </span>
          </li >
        </Tooltip>
      }
      {
        filter('Date/Time', 'date') &&
        <Tooltip title={tooltipTexts.dateTime} placement="right">
          <li className={`${!props.hasFlow ? 'list-group-item-all-center' : 'list-group-item-all'} d-flex align-items-center`} onClick={() => { addComponent('date'); toggleFlowComponents() }}>
            < img className="flow-img-all h-100" src={date} alt="images" ></img >
            <span className="list-item-title-all ">{staticTexts['dateOrTimeText']} </span>
          </li >
        </Tooltip>
      }

      {
        filter('Image', 'image') &&
        <Tooltip title={tooltipTexts.image} placement="right">
          <li className={`${!props.hasFlow ? 'list-group-item-all-center' : 'list-group-item-all'} d-flex align-items-center`} onClick={() => { addComponent('image'); toggleFlowComponents() }}>
            < img className="flow-img-all h-100" src={image} alt="images" ></img >
            <span className="list-item-title-all ">{staticTexts['imageText']} </span>
          </li >
        </Tooltip>
      }
      {
        filter('Web Link', 'contact') &&
        <Tooltip title={tooltipTexts.webLink} placement="right">
          <li className={`${!props.hasFlow ? 'list-group-item-all-center' : 'list-group-item-all'} d-flex align-items-center`} onClick={() => { addComponent('contact'); toggleFlowComponents() }}>
            < img className="flow-img-all h-100" src={contact} alt="images" ></img >
            <span className="list-item-title-all ">{staticTexts['webLinkText']} </span>
          </li >
        </Tooltip>
      }
      {
        filter('Rating', 'rating') &&
        <Tooltip title={tooltipTexts.rating} placement="right">
          <li className={`${!props.hasFlow ? 'list-group-item-all-center' : 'list-group-item-all'} d-flex align-items-center`} onClick={() => { addComponent('rating'); toggleFlowComponents() }}>
            < img className="flow-img-all h-100" src={rating} alt="images" ></img >
            <span className="list-item-title-all ">{staticTexts['ratingText']} </span>
          </li >
        </Tooltip>
      }

      {
        filter('Range', 'range') &&
        <Tooltip title={tooltipTexts.range} placement="right">
          <li className={`${!props.hasFlow ? 'list-group-item-all-center' : 'list-group-item-all'} d-flex align-items-center`} onClick={() => { addComponent('range'); toggleFlowComponents() }}>
            < img className="flow-img-all h-100" src={range} alt="images" ></img >
            <span className="list-item-title-all ">{staticTexts['rangeText']} </span>
          </li >
        </Tooltip>
      }
      {
        filter('Number', 'number') &&
        <Tooltip title={tooltipTexts.number} placement="right">
          <li className={`${!props.hasFlow ? 'list-group-item-all-center' : 'list-group-item-all'} d-flex align-items-center`} onClick={() => { addComponent('number'); toggleFlowComponents() }}>
            < img className="flow-img-all h-100" src={number} alt="images" ></img >
            <span className="list-item-title-all ">{staticTexts['numberText']} </span>
          </li >
        </Tooltip>
      }
      {
        filter('Video', 'video') &&
        <Tooltip title={tooltipTexts.video} placement="right">
          <li className={`${!props.hasFlow ? 'list-group-item-all-center' : 'list-group-item-all'} d-flex align-items-center`} onClick={() => { addComponent('video'); toggleFlowComponents() }}>
            < img className="flow-img-all h-100" src={video} alt="images" ></img >
            <span className="list-item-title-all ">{staticTexts['videoText']} </span>
          </li >
        </Tooltip>
      }
      {
        filter('Smart Question', 'smart_question') &&
        <Tooltip title={tooltipTexts.smartQuestion} placement="right">
          <li className={`${!props.hasFlow ? 'list-group-item-all-center' : 'list-group-item-all'} d-flex align-items-center`} style={{ height: '44px' }} onClick={() => { addComponent('smart_question'); toggleFlowComponents() }}>
            < img className="flow-img-all h-100" src={smart_question} alt="images" ></img >
            <span className="list-item-title-all ">{staticTexts['smartQuestionText']} </span>
          </li >
        </Tooltip>
      }
      {
        filter('Catalogue', 'catalogue') &&
        <Tooltip title={tooltipTexts.catalogue} placement="right">
          <li className={`${!props.hasFlow ? 'list-group-item-all-center' : 'list-group-item-all'} d-flex align-items-center`} onClick={() => { addComponent('catalogue'); toggleFlowComponents() }}>
            < img className="flow-img-all h-100" src={catalogue} alt="images" ></img >
            <span className="list-item-title-all ">{staticTexts['catalogueText']}</span>
          </li >
        </Tooltip>
      }
      {
        filter('Redirect', 'redirect') &&
        <li className={`${!props.hasFlow ? 'list-group-item-all-center' : 'list-group-item-all'} d-flex align-items-center`} onClick={() => { addComponent('redirect'); toggleFlowComponents() }}>
          < img className="flow-img-all h-100" src={redirect} alt="images" ></img >
          <span className="list-item-title-all ">{staticTexts['redirectText']} </span>
        </li >
      }
      {
        filter('Document', 'document') &&
        <Tooltip title={tooltipTexts.document} placement="right">
          <li className={`${!props.hasFlow ? 'list-group-item-all-center' : 'list-group-item-all'} d-flex align-items-center`} onClick={() => { addComponent('document'); toggleFlowComponents() }}>
            < img className="flow-img-all h-100" src={file} alt="images" ></img >
            <span className="list-item-title-all ">{staticTexts['documentText']} </span>
          </li >
        </Tooltip>
      }

      {
        filter('Live Chat', 'live_chat') &&
        <Tooltip title={tooltipTexts.liveChat} placement="right">
          <li className={`${!props.hasFlow ? 'list-group-item-all-center' : 'list-group-item-all'} d-flex align-items-center position-relative`} onClick={() => { addComponent('live_chat'); toggleFlowComponents() }}>
            < img className="flow-img-all h-100" src={live_chat} alt="images" ></img >
            <span className="list-item-title-all ">{staticTexts['liveChatText']} </span>
            <span className="info-new-add" >{staticTexts['new']}</span>
          </li >
        </Tooltip>
      }
      {
        filter('Address', 'address') && 
        <Tooltip title={tooltipTexts.address} placement="right">
        <li className={`${!props.hasFlow ? 'list-group-item-all-center' : 'list-group-item-all'} d-flex align-items-center position-relative`}  onClick={() => { addComponent('address'); toggleFlowComponents() }}>
          <img className="flow-img-all h-100" src={address} alt="images" ></img >
          <span className="list-item-title-all ">{staticTexts['addressText']}</span>
        </li >
        </Tooltip>
      }
      {
        filter('Single Product', 'single_product') && 
        <Tooltip title={tooltipTexts.single_product} placement="right">
          <li className={`${!props.hasFlow ? 'list-group-item-all-center' : 'list-group-item-all'} d-flex align-items-center position-relative`}  onClick={() => { addComponent('single_product'); toggleFlowComponents() }}>
            < img className="flow-img-all" src={single_product} alt="images" ></img >
            <span className="list-item-title-all ">{staticTexts['single_product_text']}</span>
          </li >
        </Tooltip>
      }
       {
        filter('Mutli Product', 'multi_product') && 
        <Tooltip title={tooltipTexts.multi_product} placement="right">
          <li className={`${!props.hasFlow ? 'list-group-item-all-center' : 'list-group-item-all'} d-flex align-items-center position-relative`}  onClick={() => { addComponent('multi_product'); toggleFlowComponents() }}>
            <img className="flow-img-all" src={multi_product} alt="images" ></img >
            <span className="list-item-title-all ">{staticTexts['multi_product_text']}</span>
          </li>
        </Tooltip>
      }
      {/* {
        isChatGPTEnabled &&
        filter('Chat GPT', 'chatGPT') &&
        <Tooltip title={tooltipTexts.chatGPT} placement="right">
          <li className={`${!props.hasFlow ? 'list-group-item-all-center' : 'list-group-item-all'} d-flex align-items-center position-relative`} onClick={() => { addComponent('chatGPT'); toggleFlowComponents() }}>
            < img className="flow-img-all h-100" src={chatGPT} alt="images" ></img >
            <span className="list-item-title-all ">Chat GPT </span>
            <span className="info-new-add" >{isChatGPTEnabled ? 'New' : 'Pro'}</span>
          </li >
        </Tooltip>
      } */}
    </ul >);
  }

  return (
    <>

      {
        <div className={`component-container ${!props.hasFlow ? 'component-container-center' : ''}`}>
          {
            !props.hasFlow &&
            <div>
              <p className={'getting-started'}>{staticTexts['startHere']}</p>
            </div>
          }
          <div style={{ position: 'relative' }}>
            <button className={`component-button ${!props.hasFlow ? 'component-button-center' : ''}`} onClick={toggleFlowComponents}
              style={{ ...(props.hasFlow && { zIndex: 1001, margin: '18px' }) }}> {staticTexts['addNewComponent']}</button>
            {
              !props.hasFlow && showFlowComponents &&
              <div>
                <ComponentList toggleFlowComponents={toggleFlowComponents} scale={true} fullLength={true} />
              </div>
            }
          </div>
          {
            props.hasFlow &&
            <div className='components-all'
              style={{ opacity: showFlowComponents ? 1 : 0, ...(props.hasFlow && { margin: '57px 18px' }) }}>
              {list()}
            </div>
          }
        </div>
      }
      <EventCanvas />
    </>
  );
}