import './notFound.css'
import image from '../../../assets/images/no-data.svg';
import { getStaticText } from '../../../assets/json/component-static-text/index';
function NotFound() {
  const staticTexts = getStaticText();

  return (
    <div className="no-data-found d-flex flex-column justify-content-around">
      <div className="icon">
        <img className='alert' src={image} alt=""></img>
      </div>
      <div className="title">{staticTexts['invalidToken']}</div>
      <div className="description">{staticTexts['pleaseTryAgain']}</div>
    </div>
  )
}

export default NotFound;