export const filterComponentByBot = (type) => {
    const botType = localStorage.getItem('botType');
    return (
        (botType?.toLowerCase() === 'telegram' && ['statement', 'question', 'email', 'phone', 'button', 'image', 'contact', 'number', 'location', 'video'].includes(type)) ||
        (botType?.toLowerCase() === 'facebook' && ['statement', 'question', 'email', 'phone', 'button', 'image', 'contact', 'number', 'location', 'video'].includes(type)) ||
        (botType?.toLowerCase() === 'instagram' && ['statement', 'question', 'email', 'phone', 'button', 'image', 'contact', 'number', 'video'].includes(type)) ||
        (botType?.toLowerCase() === 'whatsapp' && ['statement', 'catalogue', 'question', 'name', 'email', 'phone', 'button', 'image', 'contact', 'number', 'location', 'video', 'document', 'address', 'single_product', 'multi_product'].includes(type)) ||
        (botType?.toLowerCase() === 'website' && ['statement', 'question', 'name', 'email', 'phone', 'button', 'multi_select', 'appointment', 'image', 'rating', 'contact', 'date', 'range', 'number', 'location', 'video', 'file', 'live_chat', 'smart_question', 'chatGPT'].includes(type))
    );
}

export const showComponentAnimation = (questionId) => {
    const questionElement = window.document.getElementById(questionId);
    if (questionElement) {
        questionElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        questionElement.style.width = "0%";
        questionElement.style.overflow = "hidden";
        setTimeout(() => {
            questionElement.style.width = "100%";
        }, [1]);
        setTimeout(() => {
            questionElement.style.overflow = "inherit";

        }, [1000])
    }
}