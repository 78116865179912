import { useFormContext } from "react-hook-form";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import React from "react";
import { useSelector } from "react-redux";
import { UPDATE_ACTIVE_QUESTION } from '../../../../redux/actions/actions';
import { useDispatch } from 'react-redux';
import { TextField } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { getStaticText } from '../../../../assets/json/component-static-text/index';


export default function Email(props) {
  const methods = useFormContext();
  const dispatch = useDispatch();
  const activeQuestion = useSelector(state => state.activeQuestion);
  const emailData = useSelector(state => state.activeQuestion.message.configureDomains)
  const staticTexts = getStaticText();

  /**
   * In order to set default values for options, otheriwse it returns undefined
   */
  // methods.setValue('message.configureDomains.selectedDomainsList', emailData.selectedDomainsList || [])

  const handleChipDelete = (domain) => {
    const domains = (emailData.selectedDomainsList || []).filter(item => item !== domain);
    methods.setValue('message.configureDomains.selectedDomainsList', domains);
    dispatch({
      type: UPDATE_ACTIVE_QUESTION,
      payload: {
        message: {
          ...activeQuestion.message,
          configureDomains: {
            ...emailData,
            selectedDomainsList: domains
          }
        }
      }
    });
  };

  const handleDomainDropdownChange = (e, data) => {
    methods.setValue('message.configureDomains.domainAction', data);
    dispatch({
      type: UPDATE_ACTIVE_QUESTION,
      payload: {
        message: {
          ...activeQuestion.message,
          configureDomains: {
            ...emailData,
            domainAction: data
          }
        }
      }
    });

  }

  const addSelectedDomain = (e) => {
    if (e.key === 'Enter' && e.target.value.length) {
      e.preventDefault();
      const domain = e.target.value.trim().split(" ").join("");

      //TODO add toast here
      if ((emailData.selectedDomainsList || []).includes(domain)) {
        console.log('Domain already exists');
        return;
      }
      methods.setValue('message.configureDomains.selectedDomainsList', [...(emailData.selectedDomainsList || []), domain]);
      dispatch({
        type: UPDATE_ACTIVE_QUESTION,
        payload: {
          message: {
            ...activeQuestion.message,
            configureDomains: {
              ...emailData,
              selectedDomainsList: [...(emailData.selectedDomainsList || []), domain]
            }
          }
        }
      });
      e.target.value = '';
    }

  }


  return (
    <>

      <div className="message-container mb-0 pb-0">

        <div className="my-4">
          <FormControl className="custom-input-box" size="small" fullWidth>
            <InputLabel >{staticTexts['includeOrExcludeSpecifiedDomains']} </InputLabel>
            <Select
              label={staticTexts['includeOrExcludeSpecifiedDomains']}
              value={emailData?.domainAction}
              sx={{ backgroundColor: "#F3F4F5" }}
              MenuProps={{
                sx: {
                  minHeight: "250px",
                  height: "calc(100vh - 400px)"
                }
              }}
            >
              <MenuItem 
              sx={{
                "&:hover": { backgroundColor: "#E4DEF5" },
                fontSize:13
              }}
              value="include" onClick={(e) => handleDomainDropdownChange(e, 'include')}>
                {staticTexts['include']}
              </MenuItem>
              <MenuItem 
              sx={{
                "&:hover": { backgroundColor: "#E4DEF5" },
                fontSize:13
              }}
              value="exclude" onClick={(e) => handleDomainDropdownChange(e, 'exclude')}>
                {staticTexts['exclude']}
              </MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="custom-input-box input-group mb-3" style={{ width: "100%" }}>
          <TextField
            label={staticTexts['domain']}
            size="small"
            fullWidth
            className="form-control size-14 theme-input"
            placeholder={staticTexts['typeDomainsAndHitEnter']}
            onKeyPress={(e) => addSelectedDomain(e)}
            InputProps={{
              sx: {
                fontSize:14, 
              }
            }}
            InputLabelProps={{
              sx: {
                fontSize:14,
              }
            }}
          />
        </div>
      </div>

      <div className="message-container domain-list-container" >

        <Stack direction="row" spacing={1} style={{ flexWrap: 'wrap' }}>
          {((emailData?.selectedDomainsList || []).map((domain, index) => {
            return (
              <React.Fragment key={index}>
                <Chip
                  label={domain}
                  onDelete={() => handleChipDelete(domain)}
                  color="primary"
                  style={{ marginBottom: '10px', marginRight: '10px', marginLeft: '0px' }}
                  defaultValue={emailData?.selectedDomainsList[index]}
                  {...methods.register(`message.configureDomains.selectedDomainsList.${index}`)}
                />

              </React.Fragment>
            )

          }))
          }
        </Stack>

      </div>

      <div className="custom-input-box input-group mb-3">
        <TextField
          label={staticTexts['errorMessageIfUserEnteredAboveMentionedDomains']}
          size="small"
          fullWidth
          className="form-control size-14 theme-input"
          placeholder={staticTexts['enterErrorMessageOnInput']}
          defaultValue={emailData?.domainErrorMessage}
          {...methods.register("message.configureDomains.domainErrorMessage")}
          InputProps={{
            sx: {
              fontSize:14, 
            }
          }}
          InputLabelProps={{
            sx: {
              fontSize:14,
            }
          }}
        />
      </div>

    </>
  );
}