import { useSelector } from "react-redux";
import { httpRequest } from "./network";

export const BotMethods = () => {
  const botId = useSelector(state => state.currentBotDetails.botId);
  const flowId = useSelector(state => state.currentBotDetails.flowId);
  const botType = useSelector(state => state.currentBotDetails.botType);
  
  const getFlowUrl = (botType) => {
    switch (botType) {
      case 'website':
        return `bots/${botId}`;
      case 'whatsapp':
        return `whatsapp-automation/flows/flow/${flowId}`;
      case 'facebook':
        return `facebook-automation/flows/flow/${flowId}`;
      case 'telegram':
        return `telegram-automation/flows/flow/${flowId}`;
      case 'instagram':
        return `instagram-automation/flows/flow/${flowId}`;
      default:
        return `bots/flow/${flowId}`;
    }
  }

  const getFlowPayload = (botType, data) => {
    switch (botType) {
      case 'website':
        return {
          flows: [
            data
          ]
        };
      case 'whatsapp':
        return data;
      case 'facebook':
        return data;
      case 'telegram':
        return data;
      case 'instagram':
        return data;
      default:
        return data;
    }
  }

  const updateBot = (data) => new Promise((resolve, reject) => {    
    const url = getFlowUrl(botType);
    const payload = getFlowPayload(botType, { ...data, _id: undefined, createdAt: undefined });
    httpRequest('PUT', url, payload)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });

  return { updateBot };

}

/**
 * Author: Satyam Sharma
 * Date: 8th July 2023
 * To be Deprecated: 20th July 2023
 * All three methods to be deprecated since the methods have been moved inside a component to be able to use redux.
 * Will be removed once the new functionality shows no bugs
 */

export const updateBot = (data) => new Promise((resolve, reject) => {
  const botType = localStorage.getItem('botType');
  
  const url = getFlowUrl(botType);
  const payload = getFlowPayload(botType, { ...data, _id: undefined, createdAt: undefined });
  httpRequest('PUT', url, payload)
    .then(({ data }) => {
      resolve(data);
    })
    .catch(error => {
      reject(error);
    });
});

const getFlowUrl = (botType) => {
  const botId = localStorage.getItem('botId');
  let flowId;
  localStorage.getItem("flowId", flowId)
    ? flowId = localStorage.getItem("flowId", flowId)
    : flowId = '';
  switch (botType) {
    case 'website':
      return `bots/${botId}`;
    case 'whatsapp':
      return `whatsapp-automation/flows/flow/${flowId}`;
    case 'facebook':
      return `facebook-automation/flows/flow/${flowId}`;
    case 'telegram':
      return `telegram-automation/flows/flow/${flowId}`;
    case 'instagram':
      return `instagram-automation/flows/flow/${flowId}`;
    default:
      return `bots/flow/${flowId}`;
  }
}

const getFlowPayload = (botType, data) => {
  let flowId;
  localStorage.getItem("flowId", flowId)
    ? flowId = localStorage.getItem("flowId", flowId)
    : flowId = '';
  switch (botType) {
    case 'website':
      return {
        flows: [
          data
        ]
      };
    case 'whatsapp':
      return data;
    case 'facebook':
      return data;
    case 'telegram':
      return data;
    case 'instagram':
      return data;
    default:
      return data;
  }
}