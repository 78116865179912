import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import QuestionCustomization from './QuestionCustomization';
import { getStaticText } from '../../../assets/json/component-static-text/index';


const staticTexts = getStaticText();
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box height={"86vh"} className='scroll-content'>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

/**
* Author: Apoorv Saxena
* Date: 7 June, 20023
* Summary: Show data tab wise
* Description: Creating this parent component for keeping separate tab for customize and advance option
*/
export default function QuestionCustomizationLayout() {
  const [activeTab, setActiveTab] = React.useState(0);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <div className="title-v1">
        {staticTexts['customizeBotMessages']}
      </div>
      <Box sx={{ width: '100%', marginTop: "50px" }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={activeTab} onChange={handleChange} style={{ fontWeight: "200" }} TabIndicatorProps={{ style: { background: "#212529" } }}>
            <Tab label={staticTexts['customize']} {...a11yProps(0)} style={{ color: "#212529", textTransform: "capitalize" }} />
            <Tab label={staticTexts['advance']} {...a11yProps(1)} style={{ color: "#212529", textTransform: "capitalize" }} />
          </Tabs>
        </Box>
        <TabPanel  value={activeTab} index={0}>
          <QuestionCustomization tab={"customize"} />
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <QuestionCustomization tab={"advance"} />
        </TabPanel>
      </Box>
    </>
  );
}