import { useState } from 'react';
import { useFormContext } from "react-hook-form";
import { TextField } from '@mui/material';
import { getStaticText } from '../../../../assets/json/component-static-text/index';

export default function RangeComponent(props) {
  const methods = useFormContext();
  const [questionData, setQuestionData] = useState(props.data);
  const staticTexts = getStaticText();

  return (
    <>
      <div className="row mb-2">
        <div className="col-md-6 d-flex flex-column">
          <div className="custom-input-box input-group mb-3 w-75" >
            <TextField
              label={staticTexts['prefix']}
              size="small"
              fullWidth
              className="form-control theme-input size-14"
              placeholder={staticTexts['from']}
              defaultValue={questionData.rangePrefix}
              {...methods.register("rangePrefix", { required: "Prefix is Required" })}
              InputProps={{
                sx: {
                  fontSize:14, 
                }
              }}
              InputLabelProps={{
                sx: {
                  fontSize:14,
                }
              }}
            />
          </div>
        </div>


        <div className="col-md-6 d-flex flex-column">
          <div className="custom-input-box input-group mb-3 w-75" >
            <TextField
              label={staticTexts['suffix']}
              size="small"
              fullWidth
              className="form-control theme-input size-14"
              placeholder={staticTexts['to']}
              defaultValue={questionData.rangeSuffix}
              {...methods.register("rangeSuffix", { required: "Suffix is Required" })}
              InputProps={{
                sx: {
                  fontSize:14, 
                }
              }}
              InputLabelProps={{
                sx: {
                  fontSize:14,
                }
              }}
            />
          </div>
        </div>

      </div>

      <div className="row mb-2">
        <div className="col-md-6 d-flex flex-column">
          <div className="custom-input-box input-group mb-3 w-75" >
            <TextField
              label={staticTexts['minRange']}
              size="small"
              fullWidth
              className="form-control theme-input size-14"
              placeholder={staticTexts['to']}
              defaultValue={questionData.minRange}
              {...methods.register("minRange", { required: "Min Range is Required" })}
              InputProps={{
                sx: {
                  fontSize:14, 
                }
              }}
              InputLabelProps={{
                sx: {
                  fontSize:14,
                }
              }}
            />
          </div>
        </div>

        <div className="col-md-6 d-flex flex-column">
          <div className="custom-input-box input-group mb-3 w-75" >
            <TextField
              label={staticTexts['maxRange']}
              size="small"
              fullWidth
              className="form-control theme-input size-14"
              placeholder={staticTexts['to']}
              defaultValue={questionData.maxRange}
              {...methods.register("maxRange", { required: "Max Range is Required" })}
              InputProps={{
                sx: {
                  fontSize:14, 
                }
              }}
              InputLabelProps={{
                sx: {
                  fontSize:14,
                }
              }}
            />
          </div>
        </div>
      </div>

      <div className="custom-input-box input-group mb-3 w-100" >
        <TextField
          label={staticTexts['step']}
          size="small"
          fullWidth
          className="form-control theme-input size-14"
          placeholder="5"
          defaultValue={questionData.rangeStep}
          {...methods.register("rangeStep", { required: "Step is Required" })}
          InputProps={{
            sx: {
              fontSize:14, 
            }
          }}
          InputLabelProps={{
            sx: {
              fontSize:14,
            }
          }}
        />
      </div>
    </>
  );
}