import React from 'react'
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import countries from '../../../../assets/json/countries.json';
import { UPDATE_ACTIVE_QUESTION } from '../../../../redux/actions/actions';
import './address-component.css';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { getStaticText } from '../../../../assets/json/component-static-text/index';

export default function AddressComponent(props) {
  const methods = useFormContext();
  const dispatch = useDispatch();
  const staticTexts = getStaticText();

  const currentQuestion = useSelector(state => state.activeQuestion);
  const handleCountryChange = (e, country) => {
    dispatch({
      type: UPDATE_ACTIVE_QUESTION, payload: {
        country
      }
    });
    methods.setValue('country', country);
  }

  return (
    <div className="message-container">
      <FormControl id="" className="custom-input-box mt-3" size="small" fullWidth>
        <InputLabel size="small">{ staticTexts['addressSelectCountry']}</InputLabel>
        <Select
          labelId="select-country"
          label={ staticTexts['addressSelectCountry']}
          value={currentQuestion?.country?.name}
          placeholder={ staticTexts['pleaseChooseOne']}
          sx={{
            backgroundColor: "#F3F4F5",
          }}
          MenuProps={{
            sx: {
              height: "calc(100vh - 400px)",
              width: "calc(50% - 20%)"
            }
          }}
        >
          <MenuItem value={null} disabled hidden>{ staticTexts['pleaseChooseOne']}</MenuItem>
          {
            countries.map((country, index) => {
              return (
                <MenuItem
                  sx={{
                    "&:hover": { backgroundColor: "#E4DEF5" }
                  }}
                  onKeyDown={(e) => e.stopPropagation()} key={index} value={country.name} onClick={(e) => handleCountryChange(e, country)} >
                  <span>
                    {country.name}
                  </span>
                </MenuItem>
              )
            })
          }
        </Select>
      </FormControl>
    </div>
  )
}