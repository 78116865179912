import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { TextField } from "@mui/material";
import { useEffect } from "react";
import { getStaticText } from '../../../../assets/json/component-static-text/index';

import {httpRequest} from '../../../../services/network';
export default function Catalogue() {
  const methods = useFormContext();
  const currentQuestion = useSelector(state => state.activeQuestion);
  const bot = useSelector(state => state.bot);
  const staticTexts = getStaticText();


  useEffect(() => {
    setCatlogVisibility();
  }, [])

  const setCatlogVisibility = () => {
    httpRequest(
      'POST',
      `${process.env.REACT_APP_API_META}${bot.accountApiKey}/whatsapp_commerce_settings?is_catalog_visible=true&&access_token=${bot.accessToken}`,
      )
      .catch((error) => console.log(error));
  }
  return (
    <>
      <div className="message-container mt-4">
        <TextField
          size="small"
          label={staticTexts['enterNumberWithCountryCode']}
          className="form-control theme-input size-14"
          defaultValue={currentQuestion.source}
          {...methods.register("source")}
          placeholder={staticTexts['examplePhoneNumber']}
          InputProps={{
            sx: {
              fontSize:14, 
            }
          }}
          InputLabelProps={{
            sx: {
              fontSize:14,
            }
          }}
        />
      </div>
    </>
  );
}