import { useState } from 'react';
import './index.scss'
import name from '../../../assets/images-v2/name.png';
import phone from '../../../assets/images-v2/phone.png';
import email from '../../../assets/images-v2/email.png';
import button from '../../../assets/images-v2/button.png';
import multi_select from '../../../assets/images-v2/multi_select.png';
import question from '../../../assets/images-v2/question.png';
import file from '../../../assets/images-v2/file.png';
import appointment from '../../../assets/images-v2/appointment.png';
import location from '../../../assets/images-v2/location.png';
import date from '../../../assets/images-v2/date.png';
import image from '../../../assets/images-v2/image.png';
import contact from '../../../assets/images-v2/contact.png';
import rating from '../../../assets/images-v2/rating.png';
import range from '../../../assets/images-v2/range.png';
import number from '../../../assets/images-v2/number.png';
import video from '../../../assets/images-v2/video.png';
import smart_question from '../../../assets/images-v2/smart_question.svg';
import live_chat from '../../../assets/images-v2/live_chat.png';
import chatGPT from '../../../assets/images-v2/chatGPT.png';
import document from '../../../assets/images-v1/sdffdfd.png';
import redirect from '../../../assets/images/chat-components/redirect.svg';
import catalogue from '../../../assets/images/chat-components/catalogue.svg';
import statement from '../../../assets/images-v2/statement.png';
import address from '../../../assets/images-v2/address.svg';
import single_product from '../../../assets/images-v2/single_product.svg';
import multi_product from '../../../assets/images-v2/multi_product.svg';

import { LOAD_FLOW, LOAD_NEW_QUESTION, UPDATE_FLOW, UPDATE_LOADER } from '../../../redux/actions/actions';
import { createQuestion } from '../../../utilities/create-question';
import { addQuestionChecks } from '../../../utilities/add-question-checks';
import { useDispatch, useSelector } from 'react-redux';
import { BotMethods } from '../../../services/bot';
import { Tooltip } from "@mui/material";
import { filterComponentByBot } from '../../../utilities/helper';
import { getTooltipText } from "../../../assets/json/component-tooltip/index";
import { getStaticText } from "../../../assets/json/component-static-text/index";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function ComponentV1(props) {
  let [filterby, setFilter] = useState('');
  const actualflow = useSelector(state => state.flow);
  let flowQuestions = useSelector(state => state.flow.questions);
  const isChatGPTEnabled = useSelector(state => state.isChatGPTEnabled);
  const selectedQuestion = useSelector(state => state.activeQuestion);
  const botType = useSelector(state => state.currentBotDetails.botType);
  const dispatch = useDispatch();
  const agency = useSelector(state => state.agency);
  const [expand, setExpand] = useState("frequently-used");
  const staticTexts = getStaticText();
  const tooltipTexts = getTooltipText();
  const { updateBot }  = BotMethods();
  /**
  * Author: Apoorv Saxena
  * Date: 7 June, 20023
  * Summary: Show component animation
  * Description: While we are adding new component, show component sliding instead of showing immediately
  */
  const showComponentAnimation = (questionId) => {
    const questionElement = window.document.getElementById(questionId);
    if (questionElement) {
      questionElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      questionElement.style.width = "0%";
      questionElement.style.overflow = "hidden";
      setTimeout(() => {
        questionElement.style.width = "100%";
      }, [1]);
      setTimeout(() => {
        questionElement.style.overflow = "inherit";

      }, [1])
    }
  }

  const addComponent = (type) => {
    let text = staticTexts[type];
    if (text.toLowerCase().trim().includes('botpenguin') && agency.meta.brand) {
      text = text.replace(/botpenguin/gi, agency.meta.brand)
    }
    let que = createQuestion(type, text);
    que = addQuestionChecks(botType, type, que);
    que.sendNotifications = true;

    /**
    * Author: Apoorv Saxena
    * Date: 7 June, 20023
    * Summary: finding index of question
    * Description: Find index of question and store that on that index number to show just bellow the selected component
    */
    function findQuestion(item) {
      return item.id === selectedQuestion.id;
    }
    let indexOfQuestion = flowQuestions.findIndex(findQuestion)
    const questionPayload = [...flowQuestions]
    questionPayload.splice(indexOfQuestion + 1, 0, que);
    const finalPayload = {
      ...actualflow,
      questions: questionPayload
    }

    dispatch({ type: LOAD_NEW_QUESTION, payload: { id: que.id } });
    dispatch({ type: LOAD_FLOW, payload: finalPayload });
    dispatch({ type: UPDATE_LOADER, payload: true });

    updateBot(finalPayload)
      .then((response) => {
        showComponentAnimation(que.id);
        dispatch({ type: UPDATE_LOADER, payload: false });
        dispatch({ type: UPDATE_FLOW, payload: response.questions ? response.questions : response.flows[0].questions });
      })
      .catch(error => {
        dispatch({ type: UPDATE_LOADER, payload: false });
        console.log(error);
      });
  }

  const handleChange =
    (panel) => (event, isExpanded) => {
      setExpand(isExpanded ? panel : false);
    };

  return (
    <>

      <div className="title-v1">
        {staticTexts['addChatComponent']}
      </div>

      <div className='v1-components-container px-1 container-fluid '>
        <div className='scroll-content' style={{ marginTop: "52px", paddingRight: "5px", paddingLeft: "5px" }}>
          <ul className='flow-list'>

            <Accordion disableGutters elevation={2} expanded={expand === "frequently-used"} onChange={handleChange("frequently-used")}>
              <AccordionSummary
                className='bg-color-hover'
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ padding: "0px 5px" }}
              >
                <Typography variant='body2'>{staticTexts['frequentlyUsed']}</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: "0px 10px" }}>
                {
                  filterComponentByBot('statement') &&
                  <Tooltip title={tooltipTexts.statement} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('statement'); }}>
                        <img className="flow-img-v1" src={statement} alt="images"></img>
                        <span className="components-old ">{staticTexts['messageText']} </span>
                      </div>
                    </li>
                  </Tooltip>
                }
                {
                  filterComponentByBot('name') &&
                  <Tooltip title={tooltipTexts.name} placement="right">
                    <li>
                      <div className=" d-flex" onClick={() => { addComponent('name'); }}>
                        <img className="flow-img-v1" src={name} alt="images"></img>
                        <span className="components-old ">{staticTexts['nameText']} </span>
                      </div>
                    </li>
                  </Tooltip>
                }
                {
                  filterComponentByBot('phone') &&
                  <Tooltip title={tooltipTexts.phone} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('phone'); }}>
                        <img className="flow-img-v1" src={phone} alt="images"></img>
                        <span className="components-old ">{staticTexts['phoneNumberText']} </span>
                      </div>
                    </li>
                  </Tooltip>
                }
                {
                  filterComponentByBot('email') &&
                  <Tooltip title={tooltipTexts.email} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('email'); }}>
                        <img className="flow-img-v1" src={email} alt="images"></img>
                        <span className="components-old ">{staticTexts['emailText']} </span>
                      </div>
                    </li>
                  </Tooltip>
                }
                {
                  filterComponentByBot('button') &&
                  <Tooltip title={tooltipTexts.singleChoice} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('button'); }}>
                        <img className="flow-img-v1" src={button} alt="images"></img>
                        <span className="components-old ">{staticTexts['singleChoiceText']} </span>
                      </div>
                    </li>
                  </Tooltip>
                }
                {
                  filterComponentByBot('multi_select') &&
                  <Tooltip title={tooltipTexts.multipleChoice} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('multi_select'); }}>
                        <img className="flow-img-v1" src={multi_select} alt="images"></img>
                        <span className="components-old ">{staticTexts['multipleChoiceText']} </span>
                      </div>
                    </li>
                  </Tooltip>
                }
                {
                  filterComponentByBot('question') &&
                  <Tooltip title={tooltipTexts.question} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('question'); }}>
                        <img className="flow-img-v1" src={question} alt="images"></img>
                        <span className="components-old ">{staticTexts['textQuestionsText']}</span>
                      </div>
                    </li>
                  </Tooltip>
                }
              </AccordionDetails>
            </Accordion>

            <Accordion disableGutters elevation={2} expanded={expand === "request-information"} onChange={handleChange("request-information")}>
              <AccordionSummary
                className='bg-color-hover'
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ padding: "0px 5px" }}
              >
                <Typography variant='body2'>{staticTexts['requestInformation']}</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: "0px 10px" }}>
                {
                  filterComponentByBot('name') &&
                  <Tooltip title={tooltipTexts.name} placement="right">
                    <li>
                      <div className=" d-flex" onClick={() => { addComponent('name'); }}>
                        <img className="flow-img-v1" src={name} alt="images"></img>
                        <span className="components-old ">{staticTexts['nameText']} </span>
                      </div>
                    </li>
                  </Tooltip>
                }
                {
                  filterComponentByBot('phone') &&
                  <Tooltip title={tooltipTexts.phone} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('phone'); }}>
                        <img className="flow-img-v1" src={phone} alt="images"></img>
                        <span className="components-old ">{staticTexts['phoneNumberText']} </span>
                      </div>
                    </li>
                  </Tooltip>
                }
                {
                  filterComponentByBot('email') &&
                  <Tooltip title={tooltipTexts.email} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('email'); }}>
                        <img className="flow-img-v1" src={email} alt="images"></img>
                        <span className="components-old ">{staticTexts['emailText']} </span>
                      </div>
                    </li>
                  </Tooltip>
                }
                {
                  filterComponentByBot('button') &&
                  <Tooltip title={tooltipTexts.singleChoice} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('button'); }}>
                        <img className="flow-img-v1" src={button} alt="images"></img>
                        <span className="components-old ">{staticTexts['singleChoiceText']} </span>
                      </div>
                    </li>
                  </Tooltip>
                }
                {
                  filterComponentByBot('multi_select') &&
                  <Tooltip title={tooltipTexts.multipleChoice} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('multi_select'); }}>
                        <img className="flow-img-v1" src={multi_select} alt="images"></img>
                        <span className="components-old ">{staticTexts['multipleChoiceText']}</span>
                      </div>
                    </li>
                  </Tooltip>
                }
                {
                  filterComponentByBot('question') &&
                  <Tooltip title={tooltipTexts.question} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('question'); }}>
                        <img className="flow-img-v1" src={question} alt="images"></img>
                        <span className="components-old ">{staticTexts['textQuestionsText']} </span>
                      </div>
                    </li>
                  </Tooltip>
                }
                {
                  filterComponentByBot('file') &&
                  <Tooltip title={tooltipTexts.file} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('file'); }}>
                        <img className="flow-img-v1" src={file} alt="images"></img>
                        <span className="components-old ">{staticTexts['fileText']} </span>
                      </div>
                    </li>
                  </Tooltip>
                }
                {
                  filterComponentByBot('appointment') &&
                  <Tooltip title={tooltipTexts.appointment} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('appointment'); }}>
                        <img className="flow-img-v1" src={appointment} alt="images"></img>
                        <span className="components-old ">{staticTexts['appointmentText']} </span>
                      </div>
                    </li>
                  </Tooltip>
                }
                {
                  filterComponentByBot('location') &&
                  <Tooltip title={tooltipTexts.location} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('location'); }}>
                        <img className="flow-img-v1" src={location} alt="images"></img>
                        <span className="components-old ">{staticTexts['locationText']} </span>
                      </div>
                    </li>
                  </Tooltip>
                }
                {
                  filterComponentByBot('date') &&
                  <Tooltip title={tooltipTexts.dateTime} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('date'); }}>
                        <img className="flow-img-v1" src={date} alt="images"></img>
                        <span className="components-old ">{staticTexts['dateOrTimeText']} </span>
                      </div>
                    </li>
                  </Tooltip>
                }
                {
                  filterComponentByBot('rating') &&
                  <Tooltip title={tooltipTexts.rating} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('rating'); }}>
                        <img className="flow-img-v1" src={rating} alt="images"></img>
                        <span className="components-old ">{staticTexts['ratingText']} </span>
                      </div>
                    </li>
                  </Tooltip>
                }
                {
                  filterComponentByBot('range') &&
                  <Tooltip title={tooltipTexts.range} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('range'); }}>
                        <img className="flow-img-v1" src={range} alt="images"></img>
                        <span className="components-old ">{staticTexts['rangeText']} </span>
                      </div>
                    </li>
                  </Tooltip>
                }
                {
                  filterComponentByBot('number') &&
                  <Tooltip title={tooltipTexts.number} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('number'); }}>
                        <img className="flow-img-v1" src={number} alt="images"></img>
                        <span className="components-old ">{staticTexts['numericInputText']}</span>
                      </div>
                    </li>
                  </Tooltip>
                }
                {
                  filterComponentByBot('smart_question') &&
                  <Tooltip title={tooltipTexts.smartQuestion} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('smart_question'); }}>
                        <img className="flow-img-v1" src={smart_question} alt="images"></img>
                        <span className="components-old ">{staticTexts['smartQuestionText']} </span>
                      </div>
                    </li>
                  </Tooltip>
                }
                {
                  filterComponentByBot('catalogue') && <li>
                    <div className="d-flex" onClick={() => { addComponent('catalogue'); }}>
                      <img className="flow-img-v1" src={catalogue} alt="images"></img>
                      <span className="components-old ">{staticTexts['catalogueText']}</span>
                    </div>
                  </li>
                }
                {
                  filterComponentByBot('redirect') && <li>
                    <div className="d-flex" onClick={() => { addComponent('redirect'); }}>
                      <img className="flow-img-v1" src={redirect} alt="images"></img>
                      <span className="components-old ">{staticTexts['redirectText']} </span>
                    </div>
                  </li>
                }
                {
                  filterComponentByBot('document') &&
                  <Tooltip title={tooltipTexts.document} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('document'); }}>
                        <img className="flow-img-v1" src={file} alt="images"></img>
                        <span className="components-old ">{staticTexts['documentText']} </span>
                      </div>
                    </li>
                  </Tooltip>
                }
                {
                filterComponentByBot('address') &&
                <Tooltip title={tooltipTexts.address} placement="right">
                  <li>
                    <div className="d-flex" onClick={() => { addComponent('address') }}>
                      <img className="flow-img-v1" src={address} alt="images"></img>
                      <span className="components-old ">{staticTexts['addressText']}</span>
                    </div>
                  </li>
                </Tooltip>
                }
              </AccordionDetails>
            </Accordion>

            <Accordion disableGutters elevation={2} expanded={expand === "send-information"} onChange={handleChange("send-information")}>
              <AccordionSummary
                className='bg-color-hover'
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ padding: "0px 5px" }}
              >
                <Typography variant='body2'>{staticTexts['sendInformation']}</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: "0px 10px" }}>
                {
                  filterComponentByBot('statement') &&
                  <Tooltip title={tooltipTexts.statement} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('statement'); }}>
                        <img className="flow-img-v1" src={statement} alt="images"></img>
                        <span className="components-old ">{staticTexts['messageText']} </span>
                      </div>
                    </li>
                  </Tooltip>
                }
                {
                  filterComponentByBot('image') &&
                  <Tooltip title={tooltipTexts.image} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('image'); }}>
                        <img className="flow-img-v1" src={image} alt="images"></img>
                        <span className="components-old ">{staticTexts['imageOrGifText']} </span>
                      </div>
                    </li>
                  </Tooltip>
                }
                {
                  filterComponentByBot('video') &&
                  <Tooltip title={tooltipTexts.video} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('video'); }}>
                        <img className="flow-img-v1" src={video} alt="images"></img>
                        <span className="components-old ">{staticTexts['videoText']} </span>
                      </div>
                    </li>
                  </Tooltip>
                }
                {
                  filterComponentByBot('contact') &&
                  <Tooltip title={tooltipTexts.webLink} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('contact'); }}>
                        <img className="flow-img-v1" src={contact} alt="images"></img>
                        <span className="components-old ">{staticTexts['webLinkText']} </span>
                      </div>
                    </li>
                  </Tooltip>
                }
                {
                filterComponentByBot('single_product') &&
                  <Tooltip title={tooltipTexts.single_product} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('single_product') }}>
                        <img className="flow-img-v1" src={single_product} alt="images"></img>
                        <span className="components-old ">{staticTexts['single_product_text']}</span>
                      </div>
                    </li>
                  </Tooltip>
                }
                {
                filterComponentByBot('multi_product') &&
                  <Tooltip title={tooltipTexts.multi_product} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('multi_product') }}>
                        <img className="flow-img-v1" src={multi_product} alt="images"></img>
                        <span className="components-old ">{staticTexts['multi_product_text']}</span>
                      </div>
                    </li>
                  </Tooltip>
                }
              </AccordionDetails>
            </Accordion>

            {
              filterComponentByBot('live_chat') &&
              <Accordion disableGutters elevation={2} expanded={expand === "trigger-action"} onChange={handleChange("trigger-action")}>
                <AccordionSummary
                  className='bg-color-hover'
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ padding: "0px 5px" }}
                >
                  <Typography variant='body2'>{staticTexts['triggerActions']}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: "0px 10px" }}>
                  {
                    filterComponentByBot('live_chat') &&
                    <Tooltip title={tooltipTexts.liveChat} leaveDelay={200} placement="right">
                      <li>
                        <div className="d-flex position-relative" onClick={() => { addComponent('live_chat'); }}>
                          <img className="flow-img-v1" src={live_chat} alt="images"></img>
                          <span className="components-old ">{staticTexts['liveChatText']} </span>
                          <span className="info-new" >{staticTexts['new']}</span>
                        </div>
                      </li>
                    </Tooltip>
                  }
                  {/* {isChatGPTEnabled &&
              filterComponentByBot('chatGPT') &&
              <Tooltip title={tooltipTexts.chatGPT} placement="right">
                <li>
                  <div className="d-flex position-relative" onClick={() => { addComponent('chatGPT'); }}>
                    <img className="flow-img-v1" src={chatGPT} alt="images"></img>
                    <span className="components-old ">Chat GPT </span>
                    <span className="info-new" >{isChatGPTEnabled ? 'New' : 'Pro'}</span>
                  </div>
                </li>
              </Tooltip>
            } */}
                </AccordionDetails>
              </Accordion>
      }   

          </ul>
        </div>
      </div>
    </>
  );

};