import { FormControl, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { httpRequest } from '../../../../services/network';
import { UPDATE_ACTIVE_QUESTION, UPDATE_TOAST } from '../../../../redux/actions/actions';
import Plus from "../../../../assets/images/plus.svg";
import Minus from "../../../../assets/images/remove_circle_black.svg";
import './multi-product.css';
import { getStaticText } from '../../../../assets/json/component-static-text/index';

export default function MultiProductComponent() {
  const dispatch = useDispatch();
  const currentQuestion = useSelector(state => state.activeQuestion);
  const methods = useFormContext();
  const bot = useSelector(state => state.bot);
  
  const [state, setState] = useState({
    catalogs: [],
    products: [],
    sections: []
  });
  const staticTexts = getStaticText();

  useEffect(() => {
    getCatalogs();
    methods.setValue('catalog', currentQuestion.catalog);
    return () => {
      setState(() => {
        return {
          catalogs: [],
          products: [],
          sections: []
        }
      });
    }
  }, []);

  useEffect(() => {
    if (currentQuestion?.catalog?.id) {
      getProducts(currentQuestion?.catalog?.id);
    }
  }, [currentQuestion, state.catalogs]);

  const getCatalogs = () => {
    httpRequest(
      'GET',
      `${process.env.REACT_APP_API_META}${bot.wabaId}/product_catalogs?access_token=${bot.accessToken}`,
    )
      .then((res) => {
        setState((state) => {
          return {
            ...state,
            catalogs: res.data
          }
        });
      })
      .catch((error) => console.log(error));
  }

  const getProducts = (id) => {
    httpRequest(
      'GET',
      `${process.env.REACT_APP_API_META}${id}/products?access_token=${bot.accessToken}`,
    )
      .then((res) =>
        setState((state) => {
          return {
            ...state,
            products: res.data
          }
        })
      )
      .catch((error) => console.log(error));
  }

  const getLabel = (type, id) => {
    switch (type) {
      case 'catalog':
          return state.catalogs.find(item => item.id === id)?.name || '';
      case 'product':
          return state.products.find(item => item.id === id)?.name || '';
      default:
          return '';
    }
  }

  const handleCatalogSelection = (id) => {
    try {
      const data = JSON.parse(JSON.stringify(currentQuestion.catalog));
      const update = {
        ...data,
        id
      };

      dispatch({ type: UPDATE_ACTIVE_QUESTION, payload: { catalog: update }});
      getProducts(id);
      methods.setValue('catalog', update);
    } catch (error) {
      console.log(error);
    }
    
  }

  const getTotalProducts = () => {
    let count = 0;
    currentQuestion.catalog.sections.forEach(section => {
      count += section.products.length;
    });
    return count;
  }

  const addProductToSection = (index) => {
    try {
      if (getTotalProducts() === 30) {
        return dispatch({
          type: UPDATE_TOAST,
          payload: { show: true, type: 'error', description: 'Whatsapp only allows max 30 items.' }
        });
      }
      const catalog = JSON.parse(JSON.stringify(currentQuestion.catalog));
      const sections =  [ ...catalog.sections ];
      const products = [ ...sections[index].products ];
      products.push({ id: '', name: '', retailer_id: '' });
      sections[index].products = products;
      catalog.sections = sections;
      dispatch({
        type: UPDATE_ACTIVE_QUESTION, 
        payload: {
          catalog: {
            ...catalog,
            sections
          }
        }
      });
      methods.setValue('catalog', catalog);
    } catch (error) {
      console.log(error);
    }
    
  }

  const removeProductFromSection = (index, sectionIndex) => {
    const catalog = JSON.parse(JSON.stringify(currentQuestion.catalog));
    const sections =  [ ...catalog.sections ];
    const products = [ ...sections[sectionIndex].products ];
    products.splice(index, 1);
    sections[sectionIndex].products = products;
    catalog.sections = sections;


    dispatch({
      type: UPDATE_ACTIVE_QUESTION, 
      payload: {
        catalog: {
          ...catalog,
          sections
        }
      }
    });
    methods.setValue('catalog', catalog);
  }

  const addSection = () => {
    dispatch({
      type: UPDATE_ACTIVE_QUESTION, payload: {
        catalog: {
          ...currentQuestion.catalog,
          sections: [...currentQuestion.catalog.sections, { title: `${staticTexts.category} ${currentQuestion.catalog.sections.length + 1}` , products: [{ retailer_id: '', name: '', id: '' }] }]
        }
      }
    });
    methods.setValue('catalog.sections', [...currentQuestion.catalog.sections, { id: null, title: `${staticTexts.category} ${currentQuestion.catalog.sections.length + 1}`, products: [{ retailer_id: null, name: '' }] }]);
  }

  const removeSection = (index) => {
    const catalog = JSON.parse(JSON.stringify(currentQuestion.catalog));
    const sections =  [ ...catalog.sections ];
    sections.splice(index, 1);
    catalog.sections = sections;

    dispatch({ type: UPDATE_ACTIVE_QUESTION, payload: { catalog } });
    methods.setValue('catalog', catalog );
  }

  const handleProductSelection = (event, sectionIndex, productIndex) => {

    const catalog =  JSON.parse(JSON.stringify(currentQuestion.catalog));
    const sections =  [ ...catalog.sections ];
    const product = state.products.find(item => item.name === event.target.value);
    sections[sectionIndex].products[productIndex] = product;
    catalog.sections = sections;

    dispatch({
      type: UPDATE_ACTIVE_QUESTION, 
      payload: {
        catalog: {
          ...catalog,
          sections
        }
      }
    });

    methods.setValue('catalog', catalog);
  }

  const updateFields = ( value, field ) => {
    dispatch({ type: UPDATE_ACTIVE_QUESTION, payload: { [field]: value } });
    methods.setValue(field, value);
  }

  return (
    <>
      <div className="custom-input-box input-group mb-4 mt-4">
        <TextField
          label={staticTexts.header}
          size="small"
          fullWidth
          className="form-control theme-input size-14"
          defaultValue={currentQuestion.header || ''}
          onChange={(event) => updateFields(event.target.value, 'header')}
          placeholder={staticTexts.enterHeader}
          aria-label="Username" aria-describedby="basic-addon1"
          InputProps={{
            sx: {
              fontSize: 14,
            }
          }}
          InputLabelProps={{
            sx: {
              fontSize: 14,
            }
          }}
        />
      </div>
      <div className="custom-input-box input-group mb-4">
        <TextField
          label={staticTexts.footer}
          size="small"
          fullWidth
          className="form-control theme-input size-14"
          defaultValue={currentQuestion.footer || ''}
          onChange={(event) => updateFields(event.target.value, 'footer')}
          placeholder={staticTexts.enterFooter}
          aria-label="Username" aria-describedby="basic-addon1"
          InputProps={{
            sx: {
              fontSize: 14,
            }
          }}
          InputLabelProps={{
            sx: {
              fontSize: 14,
            }
          }}
        />
      </div>
      <div className='message-container mb-4'>
        <FormControl id="" className="custom-input-box" size="small" fullWidth>
          <InputLabel size="small">{staticTexts.selectCatalog}</InputLabel>
          <Select
            labelId="select-catalog"
            label={staticTexts.selectCatalog}
            value={ getLabel('catalog', (currentQuestion?.catalog?.id)) }
            placeholder={staticTexts.pleaseChooseOne}
            sx={{
              backgroundColor: "#F3F4F5",
            }}
            MenuProps={{
              sx: {
                height: "calc(100vh - 400px)",
                width: "calc(50% - 20%)"
              }
            }}
          >
            <MenuItem value={null} disabled hidden>{staticTexts.pleaseChooseOne}</MenuItem>
            {
              state.catalogs.map((catalog, index) => {
                return (
                  <MenuItem
                    sx={{
                      "&:hover": { backgroundColor: "#E4DEF5" }
                    }}
                    onKeyDown={(e) => e.stopPropagation()} key={index} value={catalog.name} onClick={(e) => handleCatalogSelection(catalog.id)}>
                    <Tooltip title={catalog.name} followCursor>
                      <span>
                        {catalog.name}
                      </span>
                    </Tooltip>
                  </MenuItem>
                )
              })
            }
          </Select>
        </FormControl>
      </div>
      {
        (currentQuestion?.catalog?.sections || []).map((section, sectionIndex) => {
          return (
            <>
            <div className="single-choice-option-container position-relative">
                <div className="d-flex justify-content-between">
                  <div className="custom-input-box input-group">
                    <TextField
                      label={staticTexts.categoryTitle}
                      size="small"
                      fullWidth
                      className="form-control theme-input size-14"
                      placeholder={staticTexts.categoryTitle}
                      defaultValue={ currentQuestion?.catalog?.sections[sectionIndex]?.title ?? '' }
                      {...methods.register(`catalog.sections.${sectionIndex}.title`)}
                      InputProps={{
                        sx: {
                          fontSize: 14,
                        }
                      }}
                      InputLabelProps={{
                        sx: {
                          fontSize: 14,
                        }
                      }}
                    />
                    {
                    currentQuestion.catalog.sections.length > 1 &&
                      <div className='d-flex align-items-center' style={{ marginLeft: "10px", height: "40px" }} role="button">
                        <img src={Minus} width={25} height={25} onClick={() => removeSection(sectionIndex)} />
                      </div>
                    }

                  </div>
                </div>


              {
                (section.products || []).map((product, productIndex) => {
                  return (
                      <div className='d-flex'>
                        <FormControl id="" className="custom-input-box mt-3" size="small" fullWidth>
                          <InputLabel size="small">{staticTexts.selectProducts}</InputLabel>
                          <Select
                            labelId="select-catalog"
                            label={staticTexts.selectProducts}
                            value={ getLabel('product', currentQuestion?.catalog?.sections[sectionIndex]?.products[productIndex]?.id) }
                            onChange={(event) => handleProductSelection(event, sectionIndex, productIndex)}
                            placeholder={staticTexts.pleaseChooseOne}
                            sx={{
                              backgroundColor: "#F3F4F5",
                            }}
                            MenuProps={{
                              sx: {
                                height: "calc(100vh - 400px)",
                                width: "calc(50% - 20%)"
                              }
                            }}
                          >
                            <MenuItem value={null} disabled hidden>{staticTexts.pleaseChooseOne}.</MenuItem>
                            {
                              (state.products || []).map((catalog, metaProdIndex) => {
                                return (
                                  <MenuItem
                                    sx={{
                                      "&:hover": { backgroundColor: "#E4DEF5" }
                                    }}
                                    onKeyDown={(e) => e.stopPropagation()} key={metaProdIndex} value={catalog.name} >
                                    <Tooltip title={catalog.name} followCursor> 
                                      <span>
                                        {catalog.name}
                                      </span>
                                    </Tooltip>
                                  </MenuItem>
                                )
                              })
                            }
                          </Select>
                        </FormControl>
                        { section.products.length > 1 &&
                          <div className='d-flex align-items-center mt-3' style={{ marginLeft: "10px", height: "40px" }} role="button">
                            <img src={Minus} width={25} height={25} onClick={() => removeProductFromSection(productIndex, sectionIndex)} />
                          </div>
                        }
                        
                        { 
                          (productIndex === (section.products.length - 1)) &&
                          <div className='d-flex align-items-center mt-3' style={{ marginLeft: "10px", height: "40px" }} role="button">
                            <img src={Plus} width={22} height={25} onClick={() => addProductToSection(sectionIndex)} />
                          </div>
                        }
                      </div>
                  )
                }
                )
              }
              {
                (sectionIndex === (currentQuestion.catalog.sections.length - 1)) &&
                  <small className='text-primary text-small cursor-pointer position-absolute add-category' onClick={addSection}>+ {staticTexts.addCategory}</small>
              }
            </div>
            
            </>
          )
        })
      }
    </>
  )
}