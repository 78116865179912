import './canvas.css';
import Nodes from '../Nodes';
import CustomEdge from '../CustomEdge/index';
import { useSelector, useDispatch, } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { getIntialEdges } from '../../../utilities/react-flow/edges.js'
import { getInitialNodes } from '../../../utilities/react-flow/nodes.js'
import { ADD_CONNECTED_NODES, CLEAR_CONNECTED_NODES, LOAD_EDGES, LOAD_FLOW, LOAD_NODES, RESET_FLOW, UPDATE_LOADER, UPDATE_NODE_POSITION } from "../../../redux/actions/actions";
import ReactFlow, { addEdge, applyNodeChanges, Background, ControlButton, Controls, MiniMap, ReactFlowProvider } from 'react-flow-renderer';
import { UPDATE_DIALOGUE } from '../../../redux/actions/actions';
import Toast from '../../Shared/Toast';
import { Tooltip } from '@mui/material';
import ClearFlow from '../../Shared/ClearFlow';
import AddNewComponent from '../AddNewComponent';
import Slider from '../../Shared/Slider';
import EventCanvas from '../EventListener'; 
import { getTooltipText } from '../../../assets/json/component-tooltip/index';

const edgeTypes = { buttonedge: CustomEdge };
const nodeTypes = { bpNode: Nodes };

export default function Canvas() {
  // Redux store methods
  const dispatch = useDispatch();
  const flow = useSelector(state => state.flow.questions);
  const flowDetails = useSelector(state => state.flow);
  const nodes = useSelector(state => state.nodes);
  const edges = useSelector(state => state.edges);
  const nodePositions = useSelector(state => state.nodePositions);
  const botType = useSelector(state => state.currentBotDetails.botType);
  const [apiCalled, setApiCalled] = useState(false);
  const tooltipTexts = getTooltipText();
  useEffect(() => {

    dispatch({ type: CLEAR_CONNECTED_NODES });
    getInitialNodes(flow || [], nodePositions)
      .then(({ connectedNodes, unConnectedNodes, connectedComponents, unConnectedComponents }) => {

        dispatch({ type: LOAD_NODES, payload: [...connectedNodes, ...unConnectedNodes] });
        connectedNodes.forEach(node => {
          if (node.data.isConnected) {
            dispatch({ type: ADD_CONNECTED_NODES, payload: { id: node.id } });
          }
        });

        return getIntialEdges(connectedComponents, unConnectedComponents);
      })
      .then(edges => {

        setTimeout(() => {
          setApiCalled(true);

          const event = new Event('setCanvasViewport');
          window.dispatchEvent(event);
        }, 500);

        dispatch({ type: LOAD_EDGES, payload: edges });
      })
      // .then
      .catch(console.log);

  }, [flow, dispatch]);


  // onNodesChange handles the dragging of node on UI
  const onNodesChange = useCallback((changes) => {
    const newNodes = applyNodeChanges(changes, nodes)

    dispatch({ type: LOAD_NODES, payload: newNodes });
    dispatch({ type: UPDATE_NODE_POSITION, payload: newNodes.map(node => ({ nodeId: node.id, ...node.position })) });
  }, [nodes, dispatch]);

  const onEdgesChange = useCallback((changes) => {
    const newNodes = applyNodeChanges(changes, nodes)
    dispatch({ type: LOAD_NODES, payload: newNodes });
  }, [nodes, dispatch]);

  const clearFlow = () => {
    dispatch({ type: UPDATE_DIALOGUE, payload: { clearFlowDialogue: true } });
  }

  const autoLayout = () => {
    dispatch({ type: RESET_FLOW });
    dispatch({ type: UPDATE_NODE_POSITION, payload: [] });
    dispatch({ type: UPDATE_LOADER, payload: true });

    const currentFlow = Object.assign({}, flowDetails);
    setTimeout(() => {
      dispatch({ type: LOAD_FLOW, payload: currentFlow });
      dispatch({ type: UPDATE_LOADER, payload: false });
    }, 300)
  }

  return (
    // !!nodes.length &&
    <div style={{
      height: '100vh',
      width: '100vw',
      backgroundColor: '#F3F4F8',
      borderRadius: 10,
      cursor: 'all-scroll'
    }}>
      <Toast />
      <ClearFlow />
      <ReactFlowProvider>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          zoomOnScroll={false}
          edgeTypes={edgeTypes}
          nodeTypes={nodeTypes}
          // preventScrolling={false}
          onNodesChange={onNodesChange}
          deleteKeyCode={null}
          panOnScroll={true}
          panOnScrollSpeed={0.8}
          defaultZoom={0.45}
          minZoom={0.25}
          maxZoom={1.25}
          
        >
          <Controls showZoom={false}>
            <ControlButton               >
              <Tooltip title={tooltipTexts.clearBotFlow} arrow>
                <span
                  className="material-icons text-danger"
                  style={{ fontSize: "14px" }}
                  onClick={() => clearFlow()}
                >
                  delete
                </span>
              </Tooltip>
            </ControlButton>

            <ControlButton >
              <Tooltip title={tooltipTexts.autoLayout} arrow>
                <span
                  className="material-icons"
                  style={{ fontSize: "14px" }}
                  onClick={() => autoLayout()}
                >
                  auto_fix_normal
                </span>
              </Tooltip>
            </ControlButton>

            <ControlButton className='bp-slider'>
              <Slider />
            </ControlButton>
          </Controls>

          <Background />

          {apiCalled && <AddNewComponent hasFlow={!!nodes.length} />}

          <MiniMap
            nodeColor='#226CF4'
            nodeStrokeWidth={0}
            nodeBorderRadius={15}
            maskColor="#d6e4fd80" />
        </ReactFlow >
      </ReactFlowProvider>
    </div >
  );
}
