import "./index.scss";
import update from 'immutability-helper'

import ChatBubble from "../Common/ChatBubble";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { LOAD_ACTIVE_QUESTION, LOAD_FLOW, UPDATE_FLOW, UPDATE_LOADER, LOAD_NEW_QUESTION, UPDATE_TOAST } from "../../../redux/actions/actions";
import { BotMethods } from "../../../services/bot";
import { v4 as uuid } from 'uuid';
import { Divider, Button } from "@mui/material";
import Plus from "../../../assets/images/plus.svg"
import ChatComponent from "./component/chat-component";
import { showComponentAnimation } from "../../../utilities/helper";
import { getStaticText } from "../../../assets/json/component-static-text/index";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";


export default function FlowQuestions(props) {
  const staticTexts = getStaticText();
  const flow = useSelector(state => state.flow);
  const newQuestionAdded = useSelector(state => state.newQuestionAdded);
  const [timer, setTimer] = useState(false);
  const { updateBot }  = BotMethods();
  const [chatComponents, setChatComponents] = useState({
    list: [
      {
        key: "statement",
        value: staticTexts['messageText']
      },
      {
        key: "name",
        value: staticTexts['nameText']
      },
      {
        key: "phone",
        value: staticTexts['phoneNumberText']
      },
      {
        key: "email",
        value: staticTexts['emailText']
      },
      {
        key: "button",
        value: staticTexts['singleChoiceText']
      },
      {
        key: "multi_select",
        value: staticTexts['multipleChoiceText']
      },
      {
        key: "question",
        value: staticTexts['textQuestionsText']
      },
      {
        key: "file",
        value: staticTexts['fileText']
      },
      {
        key: "appointment",
        value: staticTexts['appointmentText']
      },
      {
        key: "location",
        value: staticTexts['locationText']
      },
      {
        key: "date",
        value: staticTexts['dateOrTimeText']
      },
      {
        key: "image",
        value: staticTexts['imageText']
      },
      {
        key: "contact",
        value: staticTexts['webLinkText']
      },
      {
        key: "rating",
        value: staticTexts['ratingText']
      },
      {
        key: "range",
        value: staticTexts['rangeText']
      },
      {
        key: "number",
        value: staticTexts['numberText']
      },
      {
        key: "video",
        value: staticTexts['videoText']
      },
      {
        key: "smart_question",
        value: staticTexts['smartQuestionText']
      },
      {
        key: "catalogue",
        value: staticTexts['catalogueText']
      },
      {
        key: "redirect",
        value: staticTexts['redirectText']
      },
      {
        key: "document",
        value: staticTexts['documentText']
      },
      {
        key: "live_chat",
        value: staticTexts['liveChatText']
      },
      {
        key: "address",
        value: staticTexts['addressText']
      },
      {
        key: "single_product",
        value: staticTexts['single_product_text']
      },
      {
        key: "multi_product",
        value: staticTexts['multi_product_text']
      },
      // {
      //   key: "chatGPT",
      //   value: "Chat GPT"
      // },
    ],
    filtered: []
  }
  );
  const [questions, setQuestions] = useState([]);
  const [quickActions, setQuickActions] = useState(undefined);

  const selectedQuestion = useSelector(state => state.activeQuestion)
  const dispatch = useDispatch();

  useEffect(() => {
    setQuestions(flow.questions || []);
  }, [flow, selectedQuestion]);

  const setActiveQuestion = (question) => {
    dispatch({ type: LOAD_ACTIVE_QUESTION, payload: { data: {} } });
    setTimeout(() => { dispatch({ type: LOAD_ACTIVE_QUESTION, payload: question }); }, 200);
  }

  useEffect(() => {
    setChatComponents({
      ...chatComponents,
      filtered: chatComponents.list
    })
  }, []);

  /**
  * Author: Apoorv Saxena
  * Date: 7 June, 20023
  * Summary: Show animation and active question
  * Description: While we are adding new component, show active component of the newly added.
  */
  useEffect(() => {
    if (newQuestionAdded.id) {
      const question = flow.questions.find(ele => ele.id === newQuestionAdded.id);
      setActiveQuestion(question);
    }
  }, [newQuestionAdded]);

  const onSearch = (e) => {
    const regex = new RegExp(e.target.value, "i");
    const matches = chatComponents.list.filter((component) => regex.test(component.value));
    setChatComponents({
      ...chatComponents,
      filtered: matches
    });
  }

  /**
  * Author: Apoorv Saxena
  * Date: 7 June, 20023
  * Summary: Reset search filter
  * Description: Reset search filter and close dropdown on leaving the component 
  */
  const resetDropdownOnLeave = () => {
    const [...doc] = document.getElementsByClassName("dropdown-menu");
    doc.forEach(element => {
      if (element.classList.contains("show") && !element.classList.contains("fixed")) {
        element.click();
      }
    });
  }

  const resetSearch = (id) => {
    const search = window.document.getElementById(id);
    search.value = "";
    setChatComponents({
      ...chatComponents,
      filtered: chatComponents.list
    });

    const [...scrollBox] = document.getElementsByClassName("scroll-box");
    scrollBox.forEach(scroll => {
      scroll?.scroll({
        top: 0
      });
    })

  }

  const getQuestions = () => {
    const data = questions.map((question, index) => {
      const hasUserReply = !['statement', 'image', 'contact', 'video', 'live_chat', 'document'].includes(question.type);
      return (
        <Draggable key={index.toString()} draggableId={String(index)} index={index}>
          {(provided, snapshot) => {
            const style = {
              boxShadow: snapshot.isDragging ? "rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset" : "", 
              borderRadius: snapshot.isDragging ? 10  : "",
              paddingLeft: snapshot.isDragging ? 10 : ""
              
            }
            return <div
              className="cursor-pointer"
              onMouseEnter={() => setQuickActions(question.id)}
              onMouseLeave={() => {
                setQuickActions(undefined);
                resetDropdownOnLeave();
              }}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
            >
              <Divider className={`custom-divider ${(quickActions === question.id) && "divider-zoom"} custom-dropdown chat-divider-bubble`}>
                <div class="remove-dropdown-icon dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => resetSearch(`input-${question.id}`)}>
                  <img width="20px" src={Plus} />
                </div>
                <ul class="dropdown-menu p-2">
                  <li><input id={`input-${question.id}`} className="w-100" placeholder={staticTexts['searchByComponentName']} onChange={onSearch} /></li>
                  <div className="scroll-box">
                    {
                      chatComponents.filtered.map(component => {
                        return <ChatComponent key={component.key} componentIndex={index} componentKey={component.key} value={component.value} />
                      })
                    }
                  </div>
                </ul>
              </Divider>
              <div
                style={style}

                className={`d-flex flex-column chat-bubble ${(quickActions === question.id) && "chat-bubble-hover"}  ${selectedQuestion?.id === question.id && "chat-bubble-active"}`}
                onClick={(e) => setActiveQuestion(question)} key={question.id} id={question.id}>
                <ChatBubble
                  key={index}
                  index={index}
                  id={question.id}
                  question={question}
                  moveCard={moveCard}
                  type={question.type}
                  text={question.label}
                  hasUserReply={hasUserReply}
                  copyQuestion={copyQuestion}
                  saveDropResult={saveDropResult}
                  setActiveQuestion={setActiveQuestion}
                  isSelected={selectedQuestion?.id === question.id}
                  quickActions={quickActions}
                />
              </div>
              {
                (quickActions === question.id) && <Divider className="custom-dropdown chat-divider-bubble">
                  <div class="remove-dropdown-icon dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => resetSearch(`input-${question.id}`)}>
                    <img width="20px" src={Plus} />
                  </div>
                  <ul class="dropdown-menu p-2">
                    <li><input id={`input-${question.id}`} className="w-100" placeholder={staticTexts['searchByComponentName']} onChange={onSearch} /></li>
                    <div className="scroll-box">
                      {
                        chatComponents.filtered.map(component => {
                          return <ChatComponent key={component.key} componentIndex={index + 1} componentKey={component.key} value={component.value} />
                        })
                      }
                    </div>
                  </ul>
                </Divider>
              }
            </div>
          }}
        </Draggable>

      )
    });

    return data;
  }


  const moveCard = useCallback((dragIndex, hoverIndex) => {
    // Reshuffle questions
    setQuestions((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      }),
    );
  }, []);

  const changeQuestionPosition = (dragIndex, dropIndex) => {
    const payload = [...questions];
    const question = payload[dragIndex];
    payload.splice(dragIndex, 1);
    payload.splice(dropIndex, 0, question);

    setQuestions(payload);
    updateBot({ ...flow, questions: payload })
      .then((response) => {
        dispatch({ type: UPDATE_FLOW, payload: response.questions ? response.questions : response.flows[0].questions });
      })
      .catch(error => {
        console.log(error);
      });
  }

  const saveDropResult = useCallback(() => {
    updateBot({ ...flow, questions })
      .then((response) => {
        dispatch({ type: UPDATE_FLOW, payload: response.questions ? response.questions : response.flows[0].questions });
      })
      .catch(error => {
        console.log(error);
      });
  });

  const copyQuestion = (question) => {
    dispatch({ type: UPDATE_LOADER, payload: true });

    /**
      * Author: Apoorv Saxena
      * Date: 7 June, 20023
      * Summary: finding index of question
      * Description: Find index of question and store that on that index number to show just bellow the selected component
      */
    function findQuestion(item) {
      return item.id === selectedQuestion.id;
    }
    let indexOfQuestion = flow.questions.findIndex(findQuestion);
    const questionPayload = [...flow.questions];
    const newQuestion = { ...question, _id: undefined, id: uuid(), next: { target: '', type: '' } };
    questionPayload.splice(indexOfQuestion + 1, 0, newQuestion);

    dispatch({
      type: LOAD_FLOW, payload: {
        ...flow,
        questions: questionPayload
      }
    });

    updateBot({
      ...flow,
      questions: questionPayload
    })
      .then((response) => {
        showComponentAnimation(newQuestion.id);
        dispatch({ type: LOAD_NEW_QUESTION, payload: { id: newQuestion.id } });
        dispatch({ type: UPDATE_LOADER, payload: false });
        dispatch({ type: UPDATE_FLOW, payload: response.questions ? response.questions : response.flows[0].questions });
        dispatch({
          type: UPDATE_TOAST,
          payload: { show: true, type: 'success', description: 'Question copied successfully' }
        });

      })
      .catch(error => {
        dispatch({
          type: UPDATE_TOAST,
          payload: { show: true, type: 'error', description: error.message || 'Could not copy, Please try again later...' }
        });
      })
      .finally(() => dispatch({ type: UPDATE_LOADER, payload: false }));

  }

  return (
    <>

      <div className="title-v1">
        {staticTexts['createOrReorderChatFlow']}
      </div>

      <div id="flow-dot-bg" style={{ marginTop: '52px', padding: '0 28px' }} className="scroll-content">
        <DragDropContext
          onDragEnd={e => {
            changeQuestionPosition(e.source.index, e.destination.index)
          }}>
          <Droppable droppableId={"table-drop"}>
            {(provided, snapshot) => <div {...provided.droppableProps} ref={provided.innerRef}>
              <div style={{paddingBottom: snapshot.isDraggingOver ? "200px" : ""}}>
                {getQuestions()}
              </div>

              <div class="custom-dropdown" style={{ display: snapshot.isDraggingOver ? "none" : "" }}>
                <div
                  className="last-ele cursor-pointer dropdown-toggle"
                  role="button" data-bs-toggle="dropdown" aria-expanded="false"
                  onClick={() => resetSearch("last-dropdown-input")}
                >
                  {staticTexts['addChatComponent']}
                </div>
                <ul class="dropdown-menu fixed cursor-pointer p-2">
                  <li><input id="last-dropdown-input" className="w-100" placeholder={staticTexts['searchByComponentName']} onChange={onSearch} /></li>
                  <div className="scroll-box">
                    {
                      chatComponents.filtered.map(component => {
                        return <ChatComponent key={component.key} componentIndex={questions.length} componentKey={component.key} value={component.value} />
                      })
                    }
                  </div>
                </ul>
              </div>

            </div>
            }
          </Droppable>
        </DragDropContext>


      </div>

    </>
  );
}

