import './App.css';
import { useEffect, useState } from 'react';
import Canvas from './components/tree-ui/Canvas';
import { useDispatch } from 'react-redux';
import Loader from './components/Shared/Loader';
import { httpRequest } from './services/network';
import NotFound from './components/Shared/NotFound';
import { LOAD_FLOW, UPDATE_CHAT_GPT, UPDATE_LOADER, LOAD_AGENCY, LOAD_ACTIVE_QUESTION, LOAD_BOT, LOAD_CURRENT_BOT_DETAILS } from './redux/actions/actions';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ReactFlowProvider } from 'react-flow-renderer';
import OldLayout from './components/old-ui/Layout';
import DeleteNode from './components/Shared/Dialog';

function App() {
  let location = useLocation();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [viewType, setViewType] = useState('v2');
  sessionStorage.clear();

  useEffect(() => {

    // eslint-disable-next-line
    const [empty, token, botId, flowId, botType, viewType, language] = location.pathname.split('/');
    if (!token || !botId || !flowId || !botType) {
      return navigate("/error", { replace: true });
    }
    setViewType(viewType);
    localStorage.setItem("currentUserToken", token);
    localStorage.setItem("language", `${language}` || 'en');

    function getBotDetails(flowId) {
      dispatch({ type: UPDATE_LOADER, payload: true });
      httpRequest('GET', getRequestUrl(flowId, botType, botId))
        .then(response => {
          dispatch({ type: LOAD_FLOW, payload: response.data[0]?.flows[0] || response.data || [] })
          const flow = response.data[0]?.flows[0]?.questions ? response.data[0]?.flows[0]?.questions[0] : response.data?.questions ? response.data?.questions[0] : [];

          dispatch({ type: LOAD_ACTIVE_QUESTION, payload: flow });
          checkIsChatGPTEnabled();
        })
        .catch((err) => {
          navigate("/error", { replace: true });
        })
        .finally(() => dispatch({ type: UPDATE_LOADER, payload: false }));
    }

    function getBot(botId) {
      dispatch({ type: UPDATE_LOADER, payload: true });
      httpRequest('GET', getBotById(botType, botId))
        .then(response => {
          dispatch({ type: LOAD_BOT, payload: response.data[0] || response.data || [] })
        })
        .catch((err) => {
          navigate("/error", { replace: true });
        })
        .finally(() => dispatch({ type: UPDATE_LOADER, payload: false }));
    }

    if (flowId) {
      getBotDetails(flowId);
      getBot(botId);
      dispatch({ type: LOAD_CURRENT_BOT_DETAILS, payload: { botId, flowId, botType } });
      localStorage.setItem("botId", botId);
      localStorage.setItem("flowId", flowId);
      localStorage.setItem("botType", botType);
    }
    

  }, [location, dispatch, navigate]);

  function getRequestUrl(flowId, botType, botId) {
    switch (botType) {
      case 'website':
        return `bots/flow/${botId}`;
      case 'whatsapp':
        return `whatsapp-automation/flows/flow/${flowId}`;
      case 'facebook':
        return `facebook-automation/flows/flow/${flowId}`;
      case 'telegram':
        return `telegram-automation/flows/flow/${flowId}`;
      case 'instagram':
        return `instagram-automation/flows/flow/${flowId}`;
      default:
        return `bots/flow/${flowId}`;
    }
  }

  function checkIsChatGPTEnabled() {
    httpRequest('GET', 'customer')
      .then(response => {
        dispatch({ type: UPDATE_CHAT_GPT, payload: !!response.data.isChatGPTEnabled })
        dispatch({ type: LOAD_AGENCY, payload: response.data.agency })
      })
      .catch((err) => {
        console.log(err);
      });
  }

  /**
   * Author: Satyam Sharma
   * Param being set by the component that is being used in the only in whatsapp components for now.
   */
  function getBotById(botType, botId) {
    switch (botType) {
      case 'whatsapp':
        return `whatsapp-automation/bot?botId=${botId}`;
      case 'website':
        return `bots/${botId}`;
      default:
        return `whatsapp-automation/bot/${botId}`;
    }
  }
  return (
    <ReactFlowProvider>
      <div className="App">
        <DeleteNode />
        {
          <div style={{ height: '1px' }}>
            <Loader />
            <Routes>
              <Route path="/error" index element={<NotFound />} />
              {
                viewType === 'v1' && <Route path="*" element={<OldLayout />} />
              }
              {
                viewType !== 'v1' && <Route path="*" element={<Canvas />} />
              }

            </Routes>
          </div>
        }

      </div>
    </ReactFlowProvider>
  );
}

export default App;
