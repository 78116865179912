import { useState, useEffect } from 'react';
import './style.scss'
import { LOAD_FLOW, LOAD_NEW_QUESTION, UPDATE_FLOW, UPDATE_LOADER } from '../../../../../redux/actions/actions';
import { createQuestion } from '../../../../../utilities/create-question';
import { addQuestionChecks } from '../../../../../utilities/add-question-checks';
import { useDispatch, useSelector } from 'react-redux';
import { BotMethods } from '../../../../../services/bot';
import { Tooltip } from "@mui/material";
import { getTooltipText } from "../../../../../assets/json/component-tooltip/index";
import { getStaticText } from "../../../../../assets/json/component-static-text/index";
import { filterComponentByBot } from '../../../../../utilities/helper';

export default function ChatComponent({ componentIndex, componentKey, value }) {
    const actualflow = useSelector(state => state.flow);
    let flowQuestions = useSelector(state => state.flow.questions);
    const selectedQuestion = useSelector(state => state.activeQuestion)
    const dispatch = useDispatch();
    const agency = useSelector(state => state.agency);
    const [image, setImage] = useState();
    const staticTexts = getStaticText();
    const tooltipTexts = getTooltipText();
    const botType = useSelector(state => state.currentBotDetails.botType);
    const { updateBot }  = BotMethods();

    useEffect(() => {
        const fetchImage = async () => {
            try {
                const response = await import(`../../../../../assets/images-v2/${componentKey}.png`) // change relative path to suit your needs
                setImage(response.default)
            } catch (err) {
                // setError(err)
            }
        }

        fetchImage()
    }, [componentKey]);


    /**
    * Author: Apoorv Saxena
    * Date: 7 June, 20023
    * Summary: Show component animation
    * Description: While we are adding new component, show component sliding instead of showing immediately
    */
    const showComponentAnimation = (questionId) => {
        document.getElementById(questionId).scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        document.getElementById(questionId).style.width = "0%";
        document.getElementById(questionId).style.overflow = "hidden";
        setTimeout(() => {
            document.getElementById(questionId).style.width = "100%";
        }, [1]);
        setTimeout(() => {
            document.getElementById(questionId).style.overflow = "inherit";

        }, [1000])
    }

    const addComponent = (type) => {
        let text = staticTexts[type];
        if (text.toLowerCase().trim().includes('botpenguin') && agency.meta.brand) {
            text = text.replace(/botpenguin/gi, agency.meta.brand)
        }
    
        let que = createQuestion(type, text);
        que = addQuestionChecks(botType, type, que);

        const questionPayload = [...flowQuestions]
        questionPayload.splice(componentIndex, 0, que);
        const finalPayload = {
            ...actualflow,
            questions: questionPayload
        }

        dispatch({ type: LOAD_NEW_QUESTION, payload: { id: que.id } });
        dispatch({ type: LOAD_FLOW, payload: finalPayload });
        dispatch({ type: UPDATE_LOADER, payload: true });

        updateBot(finalPayload)
            .then((response) => {
                showComponentAnimation(que.id);
                dispatch({ type: UPDATE_LOADER, payload: false });
                dispatch({ type: UPDATE_FLOW, payload: response.questions ? response.questions : response.flows[0].questions });
            })
            .catch(error => {
                dispatch({ type: UPDATE_LOADER, payload: false });
                console.log(error);
            });

    }

    const matchTooltipKeys = (key) => {
        let value;
        if (key === "button") {
            value = "singleChoice"
        } else if (key === "contact") {
            value = "webLink"
        } else if (key === "multi_select") {
            value = "multipleChoice";
        } else if (key === "date") {
            value = "dateTime";
        } else if (key === "smart_question") {
            value = "smartQuestion";
        } else if (key === "live_chat") {
            value = "liveChat";
        }
        else {
            value = key;
        }
        return value;
    }

    return (
        <>
            {
                filterComponentByBot(componentKey) &&
                <Tooltip 
                title={tooltipTexts[matchTooltipKeys(componentKey)]} 
                placement="right"
                >
                    <li className='li-padding'>
                        <div className="d-flex" onClick={() => { addComponent(componentKey) }}>
                            <img width="20px" height="20px" src={image} alt="images"></img>
                            <span className="component-text">{value} </span>
                        </div>
                    </li>
                </Tooltip>
            }
        </>
    );

};