import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { httpRequest } from '../../../../services/network';
import { UPDATE_ACTIVE_QUESTION } from '../../../../redux/actions/actions';
import { FormControl, InputLabel, MenuItem, Select, Tooltip } from '@mui/material';
import { getStaticText } from '../../../../assets/json/component-static-text/index';

export default function SingleProductComponent() {
    const methods = useFormContext();
    const dispatch = useDispatch();
    const currentQuestion = useSelector(state => state.activeQuestion);
    const bot = useSelector(state => state.bot);
    const [state, setState] = useState({
        catalogs: [],
        products: [],
        selectedId: ''
    });
    const staticTexts = getStaticText();
    useEffect(() => {
        getCatalogs();
        return () => {
            setState(() => {
                return {
                    catalogs: [],
                    products: [],
                    selectedId: ''
                }
            });
        }
    }, []);

    useEffect(() => {
        if (currentQuestion?.catalog?.id) {
            getProducts(currentQuestion?.catalog?.id);
        }
    }, [currentQuestion]);

    const getCatalogs = () => {
        httpRequest(
            'GET',
            `${process.env.REACT_APP_API_META}${bot.wabaId}/product_catalogs?access_token=${bot.accessToken}`,
        )
            .then((res) => {
                setState((state) => {
                    return {
                        ...state,
                        catalogs: res.data
                    }
                });
            })
            .catch((error) => console.log(error));
    }

    const getProducts = (id) => {
        httpRequest(
            'GET',
            `${process.env.REACT_APP_API_META}${id}/products?access_token=${bot.accessToken}`,
        )
            .then((res) => 
                    setState((state) => {
                    return {
                        ...state,
                        products: res.data
                        }
                    })
            )
            .catch((error) => console.log(error));
    }

    const getLabel = (type, id) => {
        switch (type) {
            case 'catalog':
                return state.catalogs.find(item => item.id === id)?.name || '';
            case 'product':
                return state.products.find(item => item.id === id)?.name || '';
            default:
                return '';
        }
    }

    const handleCatalogSelection = (id) => {
        dispatch({ type: UPDATE_ACTIVE_QUESTION, payload: { catalog: { ...currentQuestion.catalog, id } } });
        getProducts(id);
        methods.setValue('catalog.id', id);
    }

    const handleProductSelection = (product) => {
        dispatch({ type: UPDATE_ACTIVE_QUESTION, payload: { catalog: { ...currentQuestion.catalog, product: product } } });
        methods.setValue('catalog.product', product);
    }

    return (
        <>
            <div className='message-container'>
                <FormControl id="" className="custom-input-box mt-3" size="small" fullWidth>
                    <InputLabel size="small">{staticTexts['selectCatalog']}</InputLabel>
                    <Select
                        labelId="select-catalog"
                        label={staticTexts['selectCatalog']}
                        value={ getLabel('catalog', (currentQuestion?.catalog?.id)) }
                        placeholder={staticTexts['pleaseChooseOne']}
                        sx={{
                            backgroundColor: "#F3F4F5",
                        }}
                        MenuProps={{
                            sx: {
                                height: "calc(100vh - 400px)",
                                width: "calc(50% - 20%)"
                            }
                        }}
                    >
                        <MenuItem value={null} disabled hidden>{staticTexts['pleaseChooseOne']}</MenuItem>
                        {
                            state.catalogs.map((catalog, index) => {
                                return (
                                    <MenuItem
                                        sx={{
                                            "&:hover": { backgroundColor: "#E4DEF5" }
                                        }}
                                        onKeyDown={(e) => e.stopPropagation()} key={index} value={catalog.name} onClick={(e) => handleCatalogSelection(catalog.id)} >
                                        <Tooltip title={catalog.name} followCursor>
                                            <span>
                                                {catalog.name}
                                            </span>
                                        </Tooltip>
                                    </MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
            </div>
            <div className='message-container'>
            <FormControl id="" className="custom-input-box mt-3" size="small" fullWidth>
                    <InputLabel size="small">{staticTexts['selectProducts']}</InputLabel>
                    <Select
                        labelId="select-products"
                        label={staticTexts['selectProducts']}
                        value={ getLabel('product', ((currentQuestion?.catalog?.product?.id || ''))) }
                        placeholder={staticTexts['pleaseChooseOne']}
                        sx={{
                            backgroundColor: "#F3F4F5",
                        }}
                        MenuProps={{
                            sx: {
                                height: "calc(100vh - 400px)",
                                width: "calc(50% - 20%)"
                            }
                        }}
                    >
                        <MenuItem value={null} disabled hidden>{staticTexts['pleaseChooseOne']}</MenuItem>
                        {
                            state.products.map((product, index) => {
                                return (
                                    <MenuItem
                                        sx={{
                                            "&:hover": { backgroundColor: "#E4DEF5" }
                                        }}
                                        onKeyDown={(e) => e.stopPropagation()} key={index} value={product.name} onClick={(e) => handleProductSelection(product)} >
                                        <Tooltip title={product.name} followCursor>
                                            <span>
                                                {product.name}
                                            </span>
                                        </Tooltip>
                                    </MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
            </div>
        </>
    )
}