import "./index.scss";

import name from '../../../../assets/images-v2/name.png';
import phone from '../../../../assets/images-v2/phone.png';
import email from '../../../../assets/images-v2/email.png';
import button from '../../../../assets/images-v2/button.png';
import multi_select from '../../../../assets/images-v2/multi_select.png';
import question from '../../../../assets/images-v2/question.png';
import file from '../../../../assets/images-v2/file.png';
import appointment from '../../../../assets/images-v2/appointment.png';
import location from '../../../../assets/images-v2/location.png';
import date from '../../../../assets/images-v2/date.png';
import image from '../../../../assets/images-v2/image.png';
import contact from '../../../../assets/images-v2/contact.png';
import rating from '../../../../assets/images-v2/rating.png';
import range from '../../../../assets/images-v2/range.png';
import number from '../../../../assets/images-v2/number.png';
import video from '../../../../assets/images-v2/video.png';
import smart_question from '../../../../assets/images-v2/smart_question.svg';
import live_chat from '../../../../assets/images-v2/live_chat.png';
import chatGPT from '../../../../assets/images-v2/chatGPT.png';
import document from '../../../../assets/images-v1/sdffdfd.png';
import redirect from '../../../../assets/images/chat-components/redirect.svg';
import catalogue from '../../../../assets/images/chat-components/catalogue.svg';
import statement from '../../../../assets/images-v2/statement.png';
import { useRef, useState } from "react";
import DeleteNode from '../../../Shared/Dialog';
import { Chip, Tooltip } from "@mui/material";
import address from '../../../../assets/images-v2/address.svg';
import single_product from '../../../../assets/images-v2/single_product.svg'; 
import multi_product from '../../../../assets/images-v2/multi_product.svg';

import { getStaticText } from '../../../../assets/json/component-static-text/index';
import { getTooltipText } from '../../../../assets/json/component-tooltip/index';
import { UPDATE_DIALOGUE } from "../../../../redux/actions/actions";
import { useDispatch, useSelector } from "react-redux";

const images = {
  name, date, file, email, phone, image, range, video, rating, number, contact, question, location,
  redirect, catalogue, statement, live_chat, button, appointment, multi_select, smart_question, document, chatGPT, address, single_product, multi_product
}

export default function ChatBubble({ quickActions, isSelected, type, text, hasUserReply, index, id, moveCard, saveDropResult, setActiveQuestion, question, copyQuestion }) {
  const dispatch = useDispatch();
  const selector = useSelector(state => state);
  const [botId, setBotId] = useState(selector.currentBotDetails.botId);
  const staticTexts = getStaticText();
  const tooltipTexts = getTooltipText();

  return (
    <>
      <div className="dnd">
        <div className="d-flex" >
          {/* Quick Action */}
          <div className={`d-flex flex-column justify-content-end`}
            style={{ opacity: quickActions === id ? 1 : 0 }} >
            <div className="edit-question-parent" >
              <div className="edit-q-options">
                <div className='edit-q-hover' onClick={() => copyQuestion(question)}>
                  <Tooltip title={tooltipTexts.copyQuestion}>
                    <span className="material-icons-outlined">
                      content_copy
                    </span>
                  </Tooltip>
                </div>
                <div className='edit-q-hover' onClick={() => dispatch({ type: UPDATE_DIALOGUE, payload: { deleteNodeDialogue: true, deleteNodeData: question } })}>
                  <Tooltip title={tooltipTexts.deleteQuestion}>
                    <span style={{ color: "#aa263f" }} className="material-icons">
                      delete
                    </span>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>

          {/* Image */}
          <span className="d-flex flex-column justify-content-end bubble-icons">
            <img src={images[type]} style={{ maxWidth: '100%' }} ></img>
          </span>

          {/* Question Label */}
          <div className={`chat left-chat`}>
            <p style={{ margin: 0, paddingLeft: '4px' }}>{text}</p>
          </div>
        </div>
        {/* 
        {
          
        } */}
        {
          (question.name === "button" || question.name === "multi_select") ?
            <div style={{ marginTop: '8px', marginLeft: 45 }}>
              {
                question?.options?.map((option, i) => {
                  return <div className={`left-chat-option`}>
                    <Chip className="cursor-pointer" label={option.value} variant="outlined" color="primary" />
                  </div>
                })
              }
            </div>
            :
            hasUserReply &&
            <div style={{ marginTop: '8px' }}>
              <div className={`chat right-chat`}>
                <p style={{ margin: 0 }}>{staticTexts['userReply']}</p>
              </div>
            </div>
        }

      </div>
    </>
  );

}