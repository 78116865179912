import React from "react";
import { useFormContext } from "react-hook-form";
import './appointment-component.css';
import { useDispatch } from 'react-redux';
import { UPDATE_ACTIVE_QUESTION } from '../../../../redux/actions/actions';
import { useSelector } from "react-redux";
import { TextField, Checkbox, FormControlLabel } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { getStaticText } from '../../../../assets/json/component-static-text/index';

export default function AppointmentComponent(props) {
  const { tab } = props;
  const methods = useFormContext();
  const availableHours = hourDiff(30);
  const slotIntervals = ['10', '15', '30', '60', '120'];
  const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
  const agency = useSelector(state => state.agency);
  const questionData = useSelector(state => state.activeQuestion);
  const dispatch = useDispatch();
  const staticTexts = getStaticText();

  const handleCheckBoxChange = (e) => {
    dispatch({
      type: UPDATE_ACTIVE_QUESTION, payload: {
        ...questionData,
        [e.target.name]: e.target.checked
      }
    });

    methods.setValue(e.target.name, e.target.checked);

  }

  const changeAvailableDays = (e, day) => {
    let availableDays = questionData.appointment.availableDays;
    e.target.checked
      ? availableDays = [...availableDays, day]
      : availableDays = availableDays.filter(item => item !== day);
    dispatch({
      type: UPDATE_ACTIVE_QUESTION, payload: {
        appointment: {
          ...questionData.appointment,
          availableDays: availableDays
        }
      }
    });

    methods.setValue('appointment', {
      ...questionData.appointment,
      availableDays: availableDays
    });
  }
  /** Give a better name to this function */
  const changeAvailableHours = (key, value) => {
    dispatch({
      type: UPDATE_ACTIVE_QUESTION, payload: {
        appointment: {
          ...questionData.appointment,
          [key]: value
        }
      }
    });


    methods.setValue('appointment', {
      ...questionData.appointment,
      [key]: value
    });
  }

  const handleGoogleIntegration = () => {
    // window.open("https://dev.botpenguin.relinns.com.au/install/integrate/google-calendar");
  }




  return (
    <>
      {/* Appointment */}

      <div style={{ margin: '20px 0px' }} >
        {
          tab === "customize" &&
          <>
            <div className="row" style={{ padding: '0 20px' }}>
              {
                (days.map((day, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="custom-checkbox-container col-md-3 ">
                        <FormControlLabel
                          className="custom-checkbox text-capitalize"
                          control={
                            <Checkbox
                              size="small"
                              value=""
                              id={day}
                              name="availableDays"
                              checked={questionData.appointment.availableDays.includes(day)}
                              onChange={(e) => changeAvailableDays(e, day)}
                            />}
                          label={day}
                        />
                      </div>
                    </React.Fragment>
                  )
                }))
              }
            </div>

            <div className="my-4">
              <div className="row">
                <div className="col-md-6">
                  <FormControl className="custom-input-box" size="small" fullWidth>
                    <InputLabel >{staticTexts['availableHoursStartTime']}</InputLabel>
                    <Select
                      label="Available Hours Start Time"
                      value={questionData.appointment.startTime}
                      sx={{ backgroundColor: "#F3F4F5" }}
                      MenuProps={{
                        sx: {
                          minHeight: "250px",
                          height: "calc(100vh - 400px)"
                        }
                      }}
                    >
                      {
                        (availableHours.map((time, index) => {
                          return (
                            <MenuItem 
                            sx={{
                              "&:hover": { backgroundColor: "#E4DEF5" },
                              fontSize:13
                            }}
                            key={index} value={time} onClick={() => changeAvailableHours('startTime', time)}>
                              {time}
                            </MenuItem>
                          );
                        }))
                      }
                    </Select>
                  </FormControl>
                </div>

                <div className="col-md-6">
                  <FormControl className="custom-input-box" size="small" fullWidth>
                    <InputLabel >{staticTexts['availableHoursEndTime']}</InputLabel>
                    <Select
                      label="Available Hours End Time"
                      value={questionData.appointment.endTime}
                      sx={{ backgroundColor: "#F3F4F5" }}
                      MenuProps={{
                        sx: {
                          minHeight: "250px",
                          height: "calc(100vh - 400px)"
                        }
                      }}
                    >
                      {
                        (availableHours.map((time, index) => {
                          return (
                            <MenuItem
                            sx={{
                              "&:hover": { backgroundColor: "#E4DEF5" },
                              fontSize:13
                            }}
                             key={index} value={time} onClick={() => changeAvailableHours('endTime', time)}>
                              {time}
                            </MenuItem>
                          );
                        }))
                      }
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>



            <div className="my-4">
              <FormControl className="custom-input-box" size="small" fullWidth>
                <InputLabel >{staticTexts['slotInterval']}</InputLabel>
                <Select
                  label="Slot Interval"
                  value={questionData.appointment.interval}
                  sx={{ backgroundColor: "#F3F4F5" }}
                  MenuProps={{
                    sx: {
                      minHeight: "250px",
                      height: "calc(100vh - 400px)"
                    }
                  }}
                >
                  {
                    (slotIntervals.map((interval, index) => {
                      return (
                        <MenuItem 
                        sx={{
                          "&:hover": { backgroundColor: "#E4DEF5" },
                          fontSize:13
                        }}
                        key={index} value={interval} onClick={() => changeAvailableHours('interval', interval)}>
                          {interval}min
                        </MenuItem>
                      );
                    }))
                  }
                </Select>
              </FormControl>
            </div>

          </>
        }

        <div className="custom-input-box input-group my-4">
          <TextField
            label={staticTexts['messageToShowWhenSlotsUnavailable']}
            size="small"
            fullWidth
            className="form-control size-14 theme-input"
            placeholder={staticTexts['slotIsUnavailable']}
            defaultValue={props.data?.message?.unavailable}
            {...methods.register("message.unavailable", { required: "Message for unavailable slots is required" })}
            InputProps={{
              sx: {
                fontSize:14, 
              }
            }}
            InputLabelProps={{
              sx: {
                fontSize:14,
              }
            }}
          />
        </div>

        {tab === "customize" &&
          agency.type !== 'WHITE_LABEL'
          &&
          <div className="message-container my-4">
            {/* <p className="description-headings">Advanced Option</p> */}
            <button className="form-control d-flex" style={{
              background: "#F3F4F5",
              padding: "10px",
              borderRadius: "5px",
              textAlign: 'left'
            }}
              onClick={handleGoogleIntegration}
            >
              <div style={{ paddingTop: '3px' }}>
                <span className="material-icons-outlined text-primary" style={{ fontSize: '18px' }}>
                  calendar_today
                </span>
              </div>
              <p className="text-primary ms-2 mb-0 mt-0">{staticTexts['integrateWithGoogleCalendar']}</p>
            </button>
          </div>
        }

      </div>

      {tab === "advance" && <>
        {/* Success Message */}
        <div className="message-container">
          <div className="custom-input-box input-group mb-3">
            <TextField
              label={staticTexts['successMessageOnConfirmation']}
              size="small"
              fullWidth
              className="form-control size-14 theme-input"
              defaultValue={props.data?.message?.success}
              placeholder={staticTexts['yourAppointmentHasBeenBooked']}
              {...methods.register("message.success", { required: "Message for appointment confirmation is required" })}
              InputProps={{
                sx: {
                  fontSize:14, 
                }
              }}
              InputLabelProps={{
                sx: {
                  fontSize:14,
                }
              }}
            />
          </div>
        </div>

        <div className="row my-3">
          <div className="col-md-6">
            <FormControlLabel
              className="custom-checkbox"
              control={
                <Checkbox
                  size="small"
                  name="back"
                  id="flexCheckDefault"
                  value=""
                  checked={props.data.back}
                  {...methods.register("back")}
                  onChange={handleCheckBoxChange}
                />}
              label={staticTexts['allowBack']}
            />
          </div>
          <div className="col-md-6">
            <FormControlLabel
              className="custom-checkbox"
              control={
                <Checkbox
                  size="small"
                  name="skip"
                  id="flexCheckChecked"
                  value=""
                  checked={props.data.skip}
                  {...methods.register("skip")}
                  onChange={handleCheckBoxChange}
                />}
              label={staticTexts['allowVisitorToSkip']}
            />
          </div>
        </div>
      </>}
    </>
  )
    ;
}

const hourDiff = (min) => {
  let tt = 0; // start time
  const ap = [' AM', ' PM']; // AM-PM
  let hours = [];
  // loop to increment the time and push results in array
  for (let i = 0; tt < 24 * 60; i++) {
    const hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
    const mm = (tt % 60); // getting minutes of the hour in 0-55 format
    hours[i] = ('0' + (hh % 12))
      .slice(-2) + ':' + ('0' + mm)
        .slice(-2) + ap[Math.floor(hh / 12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
    tt = tt + min;
  }
  return hours;
}