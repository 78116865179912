import { v4 as uuid } from 'uuid';
import { UPDATE_DIALOGUE, UPDATE_FLOW, UPDATE_LOADER, UPDATE_TOAST, LOAD_FLOW, RESET_FLOW, UPDATE_NODE_POSITION } from '../../../redux/actions/actions';
import './quickActions.scss'
import { BotMethods } from '../../../services/bot';
import { useDispatch, useSelector } from 'react-redux';
import DeleteNode from '../../Shared/Dialog';
import { useState } from 'react';


export default function QuickActions(props) {
  const dispatch = useDispatch();
  const [botId] = useState(useSelector(state => state.currentBotDetails.botId));
  const { updateBot }  = BotMethods();

  const copyQuestion = () => {
    dispatch({ type: UPDATE_LOADER, payload: true });

    /**
      * Author: Apoorv Saxena
      * Date: 7 June, 2023
      * Summary: finding index of question
      * Description: Find index of question and store that on that index number to show just bellow the selected component
    */
   function findQuestion(item) {
     return item.id === props.nodeId;
    }
    const questionsPayload = [];
    let indexOfQuestion = props.flow.questions.findIndex(findQuestion);
    const newQuestion = { ...props.question, _id: undefined, id: uuid() };

    props.flow.questions.forEach(element => {
      if(element.id === props.nodeId) {
        questionsPayload.push({...element, next:{ ...element.next, target: newQuestion.id }});
      }  else {
        questionsPayload.push(element);
      }
    });

    questionsPayload.splice(indexOfQuestion + 1, 0, newQuestion);
    dispatch({
      type: LOAD_FLOW, payload: {
        ...props.flow,
        questions: questionsPayload
      }
    });


    updateBot({
      ...props.flow,
      questions: questionsPayload
    })
      .then((response) => {
        dispatch({ type: UPDATE_NODE_POSITION, payload: [] });

        setTimeout(() =>{
          dispatch({ type: UPDATE_LOADER, payload: false });
          dispatch({ type: UPDATE_FLOW, payload: response.questions ? response.questions : response.flows[0].questions });
          dispatch({
            type: UPDATE_TOAST,
            payload: { show: true, type: 'success', description: 'Question copied successfully' }
          });
        }, 200);

      })
      .catch(error => {
        dispatch({
          type: UPDATE_TOAST,
          payload: { show: true, type: 'error', description: error.message || 'Could not copy, Please try again later...' }
        });
      })
      .finally(() => dispatch({ type: UPDATE_LOADER, payload: false }));

  }

  return (
    <>
      <div className="edit-options-parent" >
        <div className="edit-options">
          {/* <div className='edit-hover' onClick={props.toggleDrawer(true)}>
            <span className="material-icons" >
              edit
            </span>
          </div> */}
          <div className='edit-hover' onClick={copyQuestion}>
            <span className="material-icons-outlined">
              content_copy
            </span>
          </div>
          <div className='edit-hover'
            onClick={() => { dispatch({ type: UPDATE_DIALOGUE, payload: { deleteNodeDialogue: true, deleteNodeData: props.question } }) }}
          >
            <span style={{ color: "#aa263f" }} className="material-icons" >
              delete
            </span>
          </div>
        </div>
      </div>
    </>
  )
}