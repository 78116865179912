import { useReactFlow } from "react-flow-renderer";
import { Tooltip } from '@mui/material';
import { getTooltipText } from '../../../assets/json/component-tooltip/index';

export default function Slider() {
  const reactFlowInstance = useReactFlow();
  const tooltipTexts = getTooltipText();
  const zoom = (zoomValue) => {
    reactFlowInstance.zoomTo(zoomValue / 100)
  };

  return (
    <>
      <Tooltip title={tooltipTexts.zoomInOrOut} arrow>
        <div>
          <input type="range" min="25" max="125" defaultValue="75" className="slider" id="myRange" onChange={(e) => zoom(e.target.value)} />
        </div>
      </Tooltip>
    </>
  )
}