import { LOAD_BOT, LOAD_CURRENT_BOT_DETAILS} from '../actions/actions';

export const initialState = {};

const botReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_BOT: {
      return {...action.payload} ;
    }
    default: {
      return { ...state };
    }
  }
};

const currentBotDetailsReducer = (state = { flowId: '', botId: '', botType: ''}, action) => {
  switch (action.type) {
    case LOAD_CURRENT_BOT_DETAILS: {
      return {...action.payload} ;
    }
    default: {
      return { ...state };
    }
  }
};

export { botReducer, currentBotDetailsReducer };